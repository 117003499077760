import React from 'react';
import InlineSVG from 'react-inlinesvg';
import SvgWrap from './SvgWrap';


const WrappedInlineSvg = (props) => {
  const {src, ...rest} = props;
  return (
    <SvgWrap {...rest}>
      <InlineSVG src={src}></InlineSVG>
    </SvgWrap>
  )
}

export default WrappedInlineSvg;