import React from "react";
import {
  ZoomableGroup,
  ComposableMap,
  Geographies,
  Geography,
  Marker,
  Line
} from "react-simple-maps";
import { geoMercator } from "d3-geo"

import geoJSON from '../hawaii-counties-topo.json';

const projection = geoMercator;
const zoom = 1;

// var features = feature(geoJSON, geoJSON.objects[Object.keys(geoJSON.objects)[0]]).features;

// const featureCollection = {
//   "type": "FeatureCollection",
//   "features": features
// }

// const center = (geoCentroid(featureCollection));

// const center = [-156.98, 21]; // for 800x600
const center = [-156.98, 20.675]

const proj = () =>
  projection()
  .center(center)
  .scale(8400);

function SimpleMap () {
  return (
    <div>
      <ComposableMap projection={proj()} center={center} width={800} height={526}>
        <Geographies geography={geoJSON}>
          {({ geographies, proj }) => {
            return geographies.map(geo => <Geography tabIndex={-1}  key={geo.rsmKey} geography={geo} />)
          }}
        </Geographies>
      </ComposableMap>
    </div>
  )
}

export default SimpleMap;
