import { Absolute, Flex } from "@modernary/ui-kit-core";
import React from "react";
import MapConstants from "../constants/MapConstants";
import ToolPanels from "../constants/ToolPanels";
import useMapViewer from "./context/useMapViewer";
import FlyoutRoot from "./flyout/FlyoutRoot";
import SearchResults from "./results/SearchResults";
import SearchBar from "./search/SearchBar";
import HelpPanel from "./tools/panels/HelpPanel";
import LayersToolPanel from "./tools/panels/LayersToolPanel";
import MeasurePanel from "./tools/panels/MeasurePanel";
import ToolBar from "./tools/ToolBar";

const MapOmniBox = () => {
  const {
    omniBox: { panel },
  } = useMapViewer();

  return (
    <Absolute
      bg={MapConstants.omniBox.bg}
      height={`calc(100% - ${MapConstants.omniBox.margin * 2}px)`}
      left={MapConstants.omniBox.margin}
      top={MapConstants.omniBox.margin}
      width={MapConstants.omniBox.width}
      zIndex={1000}
    >
      <FlyoutRoot />
      <Absolute height="100%" width="100%" zIndex={1002} shadow="small" bg="white">
        <Flex row height="100%">
          <Flex.Item flex={0}>
            <ToolBar />
          </Flex.Item>
          <Flex.Item flex={0}>
            <SearchBar />
          </Flex.Item>
          <Flex.Item flex={1} style={{ overflow: "hidden" }}>
            {panel === ToolPanels.SearchResults && <SearchResults />}
            {panel === ToolPanels.Layers && <LayersToolPanel />}
            {panel === ToolPanels.Help && <HelpPanel />}
            {panel === ToolPanels.Measure && <MeasurePanel />}
          </Flex.Item>
        </Flex>
      </Absolute>
    </Absolute>
  );
};

export default MapOmniBox;
