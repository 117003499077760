import React from 'react';
import styled from '@emotion/styled/macro';
import { spacing } from '../theme/theme';
import { colorMap } from '../theme/themeMapping';
import { CardHeading } from './Card';
import ItemPanel from './ItemPanel';

const separatorWidth = 1;
const separatorColor = colorMap.lightSeparator;
const headingMargin = spacing.small;
const cardActive = colorMap.cardActive;

const SplitSelectIndexOuter = styled(ItemPanel)`
  ${props => props.borderBottom ? `border-bottom: ${separatorWidth}px solid ${separatorColor}` : ''};
  ${props => props.isActive ? `background-color: ${cardActive};` : ''};
`

const SplitSelectIndexHeading = styled(CardHeading)`
  margin-bottom: ${headingMargin}px;
  ${props => props.isActive && `color: ${colorMap.normalText};`}
`;
const SplitSelectIndexBody = styled.p`
  margin: 0;
`

export const SplitSelectIndex = ({heading, children, ...rest}) => {
  return (
    <>
      <SplitSelectIndexOuter clickable {...rest}>
        {heading && 
          <SplitSelectIndexHeading isActive={rest.isActive} as="h2">{heading}</SplitSelectIndexHeading>
        }
        {children && 
          <SplitSelectIndexBody>{children}</SplitSelectIndexBody>
        }
      </SplitSelectIndexOuter>
    </>
  )
}