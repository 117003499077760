import isArray from 'lodash/isArray';
import isString from 'lodash/isString';
import TileLayer from 'ol/layer/Tile';
import { TileArcGISRest } from 'ol/source';
import PropTypes from 'prop-types';
import {
  useEffect,
  useRef
} from 'react';
import useMap from '../context/useMap';
import randomId from '../util/randomId';

const ArcGISTileLayer = ({ id, zIndex, opacity, visible, url, attributions }) => {
  const { map } = useMap();
  const layersRef = useRef(null);

  useEffect(() => {
    if (!map) {
      return;
    }

    layersRef.current = [];
    const urls = [];

    if (isString(url)) {
      urls.push(url);
    } else if (isArray(url)) {
      urls.push(...url);
    }

    urls.forEach(layerUrl => {

      const tileLayer = new TileLayer({
        id: id,
        zIndex: zIndex,
        opacity: opacity,
        visible: visible,
        source: new TileArcGISRest({
          attributions: attributions,
          url: layerUrl,
          crossOrigin: 'anonymous',
        }),
      });
      map.getLayers().push(tileLayer);
      layersRef.current.push(tileLayer);
    });

    return () => {
      if (layersRef.current) {
        layersRef.current.forEach(layer => map.removeLayer(layer));
      }
    };
  }, [map]);

  useEffect(() => {
    if (!map) {
      return;
    }

    const handler = mapEvent => {
      console.log('getZoom', mapEvent.map.getView().getZoom());
    };

    //map.on('moveend', handler);

    return () => {
      //map.un('moveend', handler);
    };
  }, [map, layersRef.current]);

  useEffect(() => {
    if (layersRef.current) {
      layersRef.current.forEach(layer => {
        layer.setZIndex(zIndex);
        layer.setOpacity(opacity);
        layer.setVisible(visible);
      });
    }
  }, [zIndex, opacity, visible]);

  return null;
};

/**
 * TODO Document me
 *
 * https://openlayers.org/en/latest/apidoc/module-ol_layer_Base-BaseLayer.html
 */
ArcGISTileLayer.propTypes = {
  id: PropTypes.any,
  zIndex: PropTypes.number,
  opacity: PropTypes.number,
  visible: PropTypes.bool,
};

ArcGISTileLayer.defaultProps = {
  id: randomId(),
  zIndex: undefined,
  opacity: undefined,
  visible: true,
};

export default ArcGISTileLayer;
