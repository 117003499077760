import {
  createEmpty,
  extend
} from 'ol/extent';
import WKT from 'ol/format/WKT';

const format = new WKT();

export default entity => {
  let ext = createEmpty();

  entity.locations.forEach(location => {
    const geometry = format.readGeometryFromText(location.geometry, {
      dataProjection: 'EPSG:4326',
      featureProjection: 'EPSG:3857'
    });
    ext = extend(ext, geometry.getExtent());
  });

  return ext;
};
