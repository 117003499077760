import React from "react";
import styled from "@emotion/styled/macro";
import { gradients, spacing, colors } from "../theme/theme";
import { CenterColumnWrap, CenterColumn, Rows, Columns, Column, breakAtMQ } from "./CommonLayout";
import SearchBar from "./SearchBar";
import { Heading2, Heading3, Heading4 } from "../theme/commonType";
import { colorMap, layoutSpacing } from "../theme/themeMapping";
import SplitWithChildMargin from "./SplitWithChildMargin";
import { withRouter } from "react-router-dom";
import CalloutCard from "./CalloutCard";
import airPollutionSvg from "../icons/air-pollution.svg";
import megaphoneSvg from "../icons/megaphone.svg";
import towerSvg from "../icons/tower.svg";
import warningSvg from "../icons/warning.svg";
import cleanupSvg from "../icons/cleanup.svg";
import waterDropletSvg from "../icons/water-droplet.svg";
import toxicTankSvg from "../icons/toxic-tank.svg";
import { Box, Relative } from "@modernary/ui-kit-core";
import SimpleMap from "./SimpleMap";
import { HollowBlueButton } from "./Buttons";
import { CardHeading } from "./Card";
import EntityTypes from "../constants/EntityTypes";
import WrappedInlineSvg from "./WrappedInlineSvg";

const innerPaddingV = spacing.large;
const innerPaddingBottom = spacing.extraLarge;
const featureHeaderMarginV = 18;
const mapPadding = 32;
const columnBreakWidth = 800;

const HeroStyle = styled.div`
  display: flex;
  flex-direction: column;
  background: ${gradients.hero};
  padding-top: ${innerPaddingV}px;
  padding-bottom: ${innerPaddingBottom}px;
  /* margin: 0 ${spacing.medium}px; */
  min-height: 400px;
`;

const HeroHeading = styled(Heading2)`
  font-size: 38px;
  letter-spacing: -0.02em;
  color: ${colors.darkGreen};
  font-weight: 600;
  line-height: 1.25;
  margin: 0;
  margin-bottom: ${innerPaddingBottom}px;
  @media screen and (min-width: ${columnBreakWidth}px) {
    span {
      display: block;
    }
  }
`;

const MainColumn = styled(Column)`
  flex: 2;
  /* max-width: 720px; */
`;
const CardAsideColumn = styled(Column)`
  flex: 1;
  /* max-width: 320px; */
`;
const CardAsideSequence = styled.div`
  & > *:not(:last-child) {
    margin-bottom: 16px;
  }
`;

const FeatureHeader = styled.div`
  margin-bottom: ${featureHeaderMarginV}px;
`;

const ViewMoreLink = styled.a`
  display: flex;
  align-items: center;
  color: ${colorMap.link};
  text-decoration: none;
  border-bottom: 1px solid;
  padding: 2px 0;
  cursor: pointer;
  &:hover,
  &:focus {
    color: ${colorMap.linkHover};
  }
`;
const ViewMoreWrap = styled.div`
  display: flex;
  justify-content: center;
`;

const MapContainer = styled.div`
  background-color: hsla(0, 0%, 100%, 0.35);
  padding: ${mapPadding}px;
  border-radius: 5px;
`;

const MapEntryButtonWrap = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 10px;
  @media screen and (min-width: ${columnBreakWidth}px) {
    margin-top: 0;
    position: absolute;
    bottom: 0;
  }
`;
const MapEntryButton = styled(CalloutCard)`
  flex: 0 0 auto;
  padding: 12px 18px;
  & ${CardHeading} {
    font-size: 16px;
  }
`;

const Disclaimer = styled.div`
  background-color: hsl(53, 100%, 84%);
  padding: 20px 24px;
  border-radius: 3px;
  border: 1px solid hsl(53, 100%, 54%);
`;

const Hero = (props) => {
  return (
    <HeroStyle>
      <CenterColumnWrap gutter={layoutSpacing.screenEdgeMarginH}>
        <CenterColumn>
          <Columns gutter={60} breakAt={columnBreakWidth}>
            <MainColumn style={{ height: "100%" }}>
              <Rows>
                <HeroHeading>
                  <span>Keeping you connected to </span> <span>your local environment.</span>
                </HeroHeading>
                {/* <Box mb={innerPaddingV}>
                </Box> */}
                {/*<FeatureHeader>
                  <Heading4>Search EHA public data</Heading4>
                </FeatureHeader>*/}
                <SearchBar
                  placeholder="What can we help you find?"
                  onSubmit={(e) => {
                    e.preventDefault();
                    props.history.push("/search");
                  }}
                ></SearchBar>
                <Box mb={innerPaddingV * 1.5} />
                {/* <Box mb={innerPaddingV * .5}>
                  <Heading4>Explore with our Map Viewer</Heading4>
                </Box> */}
                <FeatureHeader>
                  <Heading4>Explore the map</Heading4>
                </FeatureHeader>
                <MapContainer>
                  <SimpleMap />
                  <Relative>
                    <MapEntryButtonWrap>
                      <MapEntryButton chevron title="Open Map Viewer" to="/map" />
                    </MapEntryButtonWrap>
                  </Relative>
                </MapContainer>
              </Rows>
            </MainColumn>
            <CardAsideColumn>
              <CardAsideSequence>
                <Heading4>Explore our data</Heading4>
                <CalloutCard
                  title={EntityTypes.emergencyReleases.label}
                  chevron={true}
                  iconSrc={megaphoneSvg}
                  to="search/emergencyReleases"
                >
                  {EntityTypes.emergencyReleases.description}
                </CalloutCard>
                <CalloutCard
                  title={EntityTypes.waterQualityAdvisories.label}
                  chevron={true}
                  iconSrc={warningSvg}
                  to="search/waterQualityAdvisories"
                >
                  {EntityTypes.waterQualityAdvisories.description}
                </CalloutCard>
                <CalloutCard
                  title={EntityTypes.uicFacilities.label}
                  chevron={true}
                  iconSrc={towerSvg}
                  to="search/uicFacilities"
                >
                  {EntityTypes.uicFacilities.description}
                </CalloutCard>
                <CalloutCard
                  title={EntityTypes.remediationSites.label}
                  chevron={true}
                  to="search/remediationSites"
                  iconSrc={cleanupSvg}
                >
                  {EntityTypes.remediationSites.description}
                </CalloutCard>
                <CalloutCard
                  title={EntityTypes.airPollutionSources.label}
                  chevron={true}
                  to="search/airPollutionSources"
                  iconSrc={airPollutionSvg}
                >
                  {EntityTypes.airPollutionSources.description}
                </CalloutCard>
                <CalloutCard
                  title={EntityTypes.hwHandlers.label}
                  chevron={true}
                  iconSrc={toxicTankSvg}
                  to="search/hwHandlers"
                >
                  {EntityTypes.hwHandlers.description}
                </CalloutCard>
                <CalloutCard
                  title={EntityTypes.npdesFacilities.label}
                  chevron={true}
                  iconSrc={waterDropletSvg}
                  to="search/npdesFacilities"
                >
                  {EntityTypes.npdesFacilities.description}
                </CalloutCard>
                {/* <ViewMoreWrap>
                  <ViewMoreLink as={Link} to="search">
                    Explore all 5 datasets <Chevron size="1em"></Chevron>
                  </ViewMoreLink>
                </ViewMoreWrap> */}
              </CardAsideSequence>
            </CardAsideColumn>
          </Columns>
        </CenterColumn>
      </CenterColumnWrap>
      <CenterColumnWrap gutter={layoutSpacing.screenEdgeMarginH}>
        <CenterColumn>
          <Box mt={innerPaddingV}>
            <Disclaimer>
              <SplitWithChildMargin gutter={12} alignItems="center" style={{ opacity: 0.8 }}>
                <div>
                  <WrappedInlineSvg src={warningSvg} size="1.5em" />
                </div>
                <div>
                  Please be advised that the map viewer is a piloted feature. We will continue to
                  enhance functionality and improve data quality as resources become available. If
                  you have any questions, please contact epwebadmin@doh.hawaii.gov.
                </div>
              </SplitWithChildMargin>
            </Disclaimer>
          </Box>
        </CenterColumn>
      </CenterColumnWrap>
    </HeroStyle>
  );
};

export default withRouter(Hero);
