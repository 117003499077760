export default {
  airPollutionSources: {
    id: "airPollutionSources",
    label: "Air Pollution Sources",
    description:
      "Covered and non-covered permitted stationary sources of air emissions subject to pollution control.",
    endpoint: "https://eha-cloud.doh.hawaii.gov/cab/api/facility-sites",
    defaultParams: {
      type: "Facility",
      hasLocations: true,
    },
    colorMarker: "#42a7ae",
    colorBg: "#f4fafb",
    getSourceSystemLink: (entity) =>
      `https://eha-cloud.doh.hawaii.gov/cab/#!/facilitySite/${entity.id}/details/view`,
  },
  emergencyReleases: {
    id: "emergencyReleases",
    label: "Emergency Releases",
    description:
      "Hazardous substance release emergencies that may cause immediate threats to human health or the environment.",
    endpoint: "https://eha-cloud.doh.hawaii.gov/iheer/api/incidents",
    defaultParams: {
      hasLocations: true,
    },
    colorMarker: "#ae4271",
    colorBg: "#fbf4f7",
    getSourceSystemLink: (entity) =>
      `https://eha-cloud.doh.hawaii.gov/iheer/#!/incident/${entity.id}/details/view`,
  },
  remediationSites: {
    id: "remediationSites",
    label: "Remediation Sites",
    description:
      "Non-emergency environmental cleanup locations with soil, sediment, and groundwater contamination.",
    endpoint: "https://eha-cloud.doh.hawaii.gov/iheer/api/sites",
    defaultParams: {
      hasLocations: true,
    },
    colorMarker: "#71ae42",
    colorBg: "#f7fbf4",
    getSourceSystemLink: (entity) =>
      `https://eha-cloud.doh.hawaii.gov/iheer/#!/site/${entity.id}/details/view`,
  },
  uicFacilities: {
    id: "uicFacilities",
    label: "UIC Facilities",
    description:
      "Facilities comprised of wells used for injecting water or other fluids into a groundwater aquifer.",
    endpoint: "https://eha-cloud.doh.hawaii.gov/sdwb/api/facilities",
    defaultParams: {
      hasLocations: true,
      expand: "locations",
    },
    colorMarker: "#ae4942",
    colorBg: "#fbf5f4",
    getSourceSystemLink: (entity) =>
      `https://eha-cloud.doh.hawaii.gov/sdwb/#!/facility/${entity.id}/details/view`,
  },
  waterQualityAdvisories: {
    id: "waterQualityAdvisories",
    label: "Water Quality Advisories",
    description:
      "Brown water advisories, high bacteria beach advisories, and sewage spills impacting coastal waters.",
    endpoint: "https://eha-cloud.doh.hawaii.gov/cwb/api/events",
    defaultParams: {
      hasLocations: true,
      expand: "locations",
    },
    colorMarker: "#4271AE",
    colorBg: "#f4f7fb",
    getSourceSystemLink: (entity) =>
      `https://eha-cloud.doh.hawaii.gov/cwb/#!/event/${entity.id}/details/view`,
  },
  hwHandlers: {
    id: "hwHandlers",
    label: "Hazardous Waste Handlers",
    description:
      "Handlers include all generators, transporters, treaters, storers, and disposers of hazardous waste.",
    endpoint: "https://eha-cloud.doh.hawaii.gov/hw/api/handlers",
    // endpoint: "https://localhost:60020/api/handlers", // development
    defaultParams: {
      hasLocations: true,
      orderBy: [{ propertyName: "number", isDescending: false }],
      take: 3000,
      skip: 0,
    },
    colorMarker: "#004E00",
    colorBg: "#F5FFFB",
    getSourceSystemLink: (entity) =>
      `https://eha-cloud.doh.hawaii.gov/hw/handler/${entity.id}/view`,
  },
  npdesFacilities: {
    id: "npdesFacilities",
    label: "NPDES Facilities",
    description:
      "Facility locations that are permitted for water pollution control into surface waters.",
    endpoint: "https://eha-cloud.doh.hawaii.gov/cwb/api/facility-sites",
    get sicEndpoint() {
      return this.endpoint.replace("facility-sites", "sics");
    },
    get naicsEndpoint() {
      return this.endpoint.replace("facility-sites", "naicss");
    },
    defaultParams: {
      hasLocations: true,
      hasPermits: true,
    },
    colorMarker: "#E4989E",
    colorBg: "#FFF9F9",
    getSourceSystemLink: (entity) =>
      `https://eha-cloud.doh.hawaii.gov/cwb/#!/facilitySite/${entity.id}/details/view`,
  },
};
