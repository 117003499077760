import styled from "@emotion/styled";
import { BorderBox, Checkbox, Flex, SingleSelect, Text } from "@modernary/ui-kit-core";
import React from "react";
import Layers from "../../../constants/Layers";
import { colors } from "../../../theme/theme";
import useMapViewer from "../../context/useMapViewer";
import ShowResultsButton from "./ShowResultsButton";

const IMAGE_SIZE = 40;
const IMAGE_FACTOR = 1.2;
const BORDER_WIDTH = 1;

const FlexSelectable = styled(Flex)`
  &:hover {
    cursor: pointer;
  }
`;

const TextLabel = ({ children }) => (
  <Text color={colors.darkBlue} fontWeight={500} fontSize={14}>
    {children}
  </Text>
);

const LayerImage = ({ layer, useShadow = true }) => (
  <BorderBox
    borderColor="grey.200"
    borderWidth={BORDER_WIDTH}
    height={IMAGE_SIZE + BORDER_WIDTH * 2}
    shadow={useShadow ? "xxsmall" : undefined}
  >
    <img src={layer.thumbnail} height={IMAGE_SIZE} width={IMAGE_SIZE * IMAGE_FACTOR} />
  </BorderBox>
);

const LayerOption = ({ layer, setBaseLayer }) => (
  <Flex gutter="medium" onClick={setBaseLayer && setBaseLayer} column alignItems="center">
    <Flex.Item flex={0}>
      <LayerImage layer={layer} />
    </Flex.Item>
    <Flex.Item flex={1}>
      <TextLabel>{layer.title}</TextLabel>
    </Flex.Item>
  </Flex>
);

const LayerSelection = ({ layer }) => (
  <Flex gutter="medium" column alignItems="center">
    <Flex.Item>
      <LayerImage layer={layer} useShadow={false} />
    </Flex.Item>
    <Flex.Item>
      <TextLabel>{layer.title}</TextLabel>
    </Flex.Item>
  </Flex>
);

export const Heading = ({ children }) => (
  <Text my="small" fontSize={15} color={colors.darkBlue} fontWeight={600}>
    {children}
  </Text>
);

const LayersToolPanel = ({}) => {
  const {
    map: { baseLayer, referenceLayers },
    setBaseLayer,
    setReferenceLayers,
  } = useMapViewer();

  const isReferenceLayerSelected = (layer) => {
    return referenceLayers.find((referenceLayer) => referenceLayer.id === layer.id) !== undefined;
  };

  const toggleReferenceLayer = (layer) => {
    let newRefLayers;
    if (isReferenceLayerSelected(layer)) {
      newRefLayers = referenceLayers.filter((referenceLayer) => referenceLayer.id !== layer.id);
    } else {
      newRefLayers = [...referenceLayers, layer];
    }
    setReferenceLayers(newRefLayers);
  };

  return (
    <Flex row gutter="large" m="medium" mr={0} height="100%">
      <Flex.Item flex={0} pr="medium">
        <ShowResultsButton />
      </Flex.Item>
      <Flex.Item flex={0} pr="medium">
        <Heading>Base Layer</Heading>
        <SingleSelect
          id="layers"
          allowClear={false}
          options={Layers.BaseLayers}
          value={baseLayer}
          onChange={setBaseLayer}
          optionId={(layer) => layer.id}
          renderOption={({ item: layer }) => (
            <LayerOption layer={layer} setBaseLayer={setBaseLayer} />
          )}
          renderSelection={({ item: layer }) => <LayerSelection layer={layer} />}
          placeholder="Base Layer"
          variant="xlarge"
        />
      </Flex.Item>
      <Flex.Item flex={0} pr="medium">
        <Heading>Reference Layers</Heading>
        {Layers.ReferenceLayers.map((layer) => (
          <FlexSelectable
            alignItems="center"
            column
            gutter="xsmall"
            key={layer.id}
            my="xsmall"
            onClick={() => toggleReferenceLayer(layer)}
          >
            <Flex.Item>
              <Checkbox value={layer} isChecked={isReferenceLayerSelected(layer)} variant="small" />
            </Flex.Item>
            <Flex.Item>
              <BorderBox
                borderWidth={1}
                borderColor="grey.200"
                width={16}
                height={16}
                bg={layer.color}
              />
            </Flex.Item>
            <Flex.Item>
              <TextLabel>{layer.title}</TextLabel>
            </Flex.Item>
          </FlexSelectable>
        ))}
      </Flex.Item>
    </Flex>
  );
};

export default LayersToolPanel;
