import React from 'react';
import styled from '@emotion/styled/macro';
import { CenterColumnWrap, CenterColumn, Columns, Column, NoWrapColumns } from './CommonLayout';
import { colors, spacing } from '../theme/theme';
import { Heading1 } from '../theme/commonType';
import { colorMap } from '../theme/themeMapping';
import SplitWithChildMargin from './SplitWithChildMargin';
import { HollowBlueButton } from './Buttons';
import AppBrand from './AppBrand';
import { headerTheme } from './LandingHeader';
import { Link } from "react-router-dom";

const HeaderOuter = styled.header`
  background-color: ${headerTheme.background};
  padding: ${headerTheme.outerPaddingVSmall}px 0;
`

function LandingHeader () {
  return (
    <HeaderOuter>
      <NoWrapColumns gutter={spacing.screenEdgeMarginH}>
        <Column>
          <SplitWithChildMargin style={{ justifyContent: 'space-between', alignItems: 'center'}} gutter={20}>
            <div>
              <Link to="/">
                <AppBrand>EHA<strong>Connect</strong></AppBrand>
              </Link>
            </div>
            <div>
              {/* <HollowBlueButton fontSize={-1} buttonSpacing={0}>Admin Login</HollowBlueButton> */}
            </div>
          </SplitWithChildMargin>
        </Column>
      </NoWrapColumns>
    </HeaderOuter>
  );
}

export default LandingHeader;
