import { useContext } from 'react';
import { MapStateContext } from './MapStateProvider';

const useMap = () => {
  const [state, dispatch] = useContext(MapStateContext);

  return {
    map: state.map
  };
};

export default useMap;
