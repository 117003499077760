import Point from 'ol/geom/Point';
import OlMap from 'ol/Map';
import OlView from 'ol/View';
import PropTypes from 'prop-types';
import React, {
  useEffect,
  useReducer,
} from 'react';
import MapConstants from '../../constants/MapConstants';
import {
  toWebMercatorCenter,
  toWebMercatorExtent
} from '../util/transform';
import { actionSetMap } from './state/actions';
import reducer from './state/reducer';

export const MapStateContext = React.createContext(null);

const MapStateProvider = ({ children, id, center, extent, zoom = MapConstants.defaultZoom, maxZoom = 23 }) => {
  const [state, dispatch] = useReducer(reducer, {});

  useEffect(() => {

    const map = new OlMap({
      target: id,
      layers: [],
      view: new OlView({
        center: [0, 0],
        zoom: zoom,
        minZoom: 1,
        maxZoom: maxZoom,
      })
    });

    if (extent) {
      map.getView().fit(toWebMercatorExtent(extent), { padding: [0, 0, 0, 0] });
    } else if (center) {
      const resolution = map.getView().getResolutionForZoom(zoom);
      map.getView().fit(new Point(toWebMercatorCenter(center)), {
        padding: [0, 0, 0, 0],
        minResolution: resolution
      });
    }

    dispatch(actionSetMap(map));

    return () => {
      map.setTarget(null);
    };
  }, []);

  /**
   * Max zoom changed.
   */
  useEffect(() => {
    if (state.map) {
      state.map.getView().setMaxZoom(maxZoom);
    }
  }, [state.map, maxZoom]);

  /**
   * Extent changed.
   */
  useEffect(() => {
    if (state.map) {
      if (extent) {
        state.map.getView().fit(toWebMercatorExtent(extent), { padding: [0, 0, 0, 0] });
      }
    }
  }, [state.map, extent]);

  return (
    <MapStateContext.Provider value={[state, dispatch]}>
      {children}
    </MapStateContext.Provider>
  );
};

/**
 * TODO Document me.
 */
MapStateProvider.propTypes = {
  id: PropTypes.string,
  initialCenter: PropTypes.arrayOf(PropTypes.number),
};

MapStateProvider.defaultProps = {
  initialCenter: undefined,
  id: Math.random().toString(36).slice(2),
};

export default MapStateProvider;
