import styled from "@emotion/styled";
import { Box, Button, Checkbox, Flex, Icon, Icons, Text } from "@modernary/ui-kit-core";
import React from "react";
import { ListItemTitle } from "../../../components/SearchListItems";
import EntityTypes from "../../../constants/EntityTypes";
import { colors } from "../../../theme/theme";
import useMapViewer from "../../context/useMapViewer";

const FlexRoot = styled(Flex)`
  &:hover {
    cursor: pointer;
  }
`;

const SearchOptions = () => {
  const {
    toggleSearchOptions,
    executeSearch,
    search: { term },
    searchOptions,
  } = useMapViewer();

  const isSelected = (entity) =>
    searchOptions.entityTypes.find((e) => e.id === entity.id) !== undefined;

  const handleOnToggleEntity = async (entity) => {
    const found = searchOptions.entityTypes.find((e) => e.id === entity.id);
    let nextEntityTypes;

    if (found) {
      nextEntityTypes = searchOptions.entityTypes.filter((e) => e.id !== found.id);
    } else {
      nextEntityTypes = [...searchOptions.entityTypes, entity];
    }

    const nextSearchOptions = {
      ...searchOptions,
      entityTypes: nextEntityTypes,
    };

    await executeSearch(term, nextSearchOptions);
  };

  return (
    <>
      <Flex column mb="large" pt="small" pr="small" gutter="xxsmall">
        <Flex.Item flex={1} pl="medium" pt="small">
          <ListItemTitle>Search Options</ListItemTitle>
        </Flex.Item>
        <Flex.Item flex={0}>
          <Button
            baseColor="grey"
            icon={Icons.X}
            fill="ghost"
            shape="round"
            onClick={toggleSearchOptions}
          />
        </Flex.Item>
      </Flex>
      <Box pl="medium" p="small">
        {Object.values(EntityTypes).map((entity) => {
          const isDisabled = searchOptions.entityTypes.length <= 1 && isSelected(entity);
          return (
            <FlexRoot
              key={entity.id}
              column
              alignItems="flex-start"
              gutter="xsmall"
              onClick={() => !isDisabled && handleOnToggleEntity(entity)}
              mb="medium"
            >
              <Flex.Item>
                <Checkbox
                  variant="small"
                  isChecked={isSelected(entity)}
                  value={entity}
                  isDisabled={isDisabled}
                />
              </Flex.Item>
              <Flex.Item pt="xsmall">
                <Icon size={18} name={Icons.Marker} color={entity.colorMarker} />
              </Flex.Item>
              <Flex.Item pt="xsmall">
                <Text fontSize={15} fontWeight={600} mb="xsmall" color={colors.darkBlue}>
                  {entity.label}
                </Text>
                <Text fontSize={13} color={colors.darkBlue}>
                  {entity.description}
                </Text>
              </Flex.Item>
            </FlexRoot>
          );
        })}
      </Box>
    </>
  );
};

export default SearchOptions;
