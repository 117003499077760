import { Box } from "@modernary/ui-kit-core";
import React from "react";

const EmptyResults = ({ term }) => {
  return (
    <Box p="small">
      No results found for: <b>{term}</b>
    </Box>
  );
};

export default EmptyResults;
