import React from "react";
import DetailMapInsert from "./components/DetailMapInsert";
import DetailRow from "./components/DetailRow";
import DetailSourceSystemLink from "./components/DetailSourceSystemLink";
import moment from "moment";
import { handlerSourceTypes, hwGenerators } from "../../../constants/HwEnums";

const getLocalMoment = (date) => {
  if (date && typeof date === "string" && date.length > 10 && !date.toString().endsWith("Z")) {
    date += "Z";
  }
  return moment(date);
};

const HwHandlersDetail = ({ entity }) => {
  return (
    <>
      <DetailRow
        label="Location Address"
        value={`${entity.locationStreet1} ${entity.locationCity} ${entity.locationZip}`}
      />
      <DetailMapInsert entity={entity} />
      <DetailRow
        label="Current Record Source"
        value={handlerSourceTypes.get(entity.sourceType)?.description}
      />

      <DetailRow
        label="Record Date"
        value={getLocalMoment(entity.createdDate).format("MM/DD/YY h:mm A zz")}
      />
      <DetailRow
        label="Generator Status"
        value={hwGenerators.get(entity.federalWasteGenerator)?.longDescription}
      />
      <DetailSourceSystemLink entity={entity} />
    </>
  );
};

export default HwHandlersDetail;
