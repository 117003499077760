import React from 'react';
import Map from '../../../../map/Map';
import { toLonLatExtent } from '../../../../map/util/transform';
import useMapViewer from '../../../context/useMapViewer';
import BaseLayer from '../../../map/layer/BaseLayer';
import EntityLayer from '../../../map/layer/EntityLayer';
import extentEntityLocations from '../../../map/util/extentEntityLocations';
import DetailRow from './DetailRow';

const DetailMapInsert = ({ entity }) => {
  const { map: { maxZoom } } = useMapViewer();

  if (!entity.extra.hasLocation) {
    return null;
  }

  return (
    <DetailRow vertical>
      <Map
        extent={toLonLatExtent(extentEntityLocations(entity))}
        id="detail-map"
        width="100%"
        height={200}
        maxZoom={maxZoom}
      >
        <BaseLayer />
        <EntityLayer entity={entity} />
      </Map>
    </DetailRow>
  );
};

export default DetailMapInsert;
