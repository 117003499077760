import PropTypes from 'prop-types';
import React, {
  useEffect,
  useRef
} from 'react';
import AutoSizer from 'react-virtualized-auto-sizer';
import { FixedSizeList } from 'react-window';

const WindowedSearchResults = ({ renderItem, results, isSearchResultEmpty, scrollToIndex }) => {
  const listRef = useRef(null);

  /**
   * Scroll to top when results change.
   */
  useEffect(() => {
    if (listRef.current && !isSearchResultEmpty) {
      listRef.current.scrollToItem(0);
    }
  }, [results, isSearchResultEmpty]);

  /**
   * Scroll to selected item.
   */
  useEffect(() => {
    if (scrollToIndex >= 0 && listRef.current) {
      listRef.current.scrollToItem(scrollToIndex);
    }
  }, [scrollToIndex]);

  return (
    <AutoSizer>
      {({ height, width }) => {
        return (
          <FixedSizeList
            ref={listRef}
            width={width}
            height={height}
            itemCount={results.length}
            itemSize={108}
            itemData={{
              items: results,
            }}
          >
            {({ index, data, style }) => {
              const { items } = data;
              return renderItem({ item: items[index], style, index });
            }}
          </FixedSizeList>
        );
      }}
    </AutoSizer>
  );
};

WindowedSearchResults.propTypes = {
  results: PropTypes.array,
  children: PropTypes.func,
};

WindowedSearchResults.defaultProps = {
  results: [],
  children: () => null,
};

export default WindowedSearchResults;
