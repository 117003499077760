import EntityTypes from './EntityTypes';
import Layers from './Layers';
import ToolPanels from './ToolPanels';

export default {
  center: [-157.5321300439421, 20.34323399543934],
  extent: [-162.64613955035261, 18.473150177780255, -154.12279110463427, 22.66149158205188],
  omniBox: {
    bg: 'rgba(255,255,255,1)',
    margin: 16,
    width: 340
  },
  defaultZoom: 8,
  duration: 1000,
  defaultSearchTerm: '',
  //defaultSearchTerm: 'Honolulu Rail Transit Project',
  defaultPanel: ToolPanels.SearchResults,
  baseLayer: 4,
  defaultBaseLayer: Layers.BaseLayers[1],
  defaultSearchOptions: {
    maxResults: 10000,
    entityTypes: Object.values(EntityTypes)
  }
};



