import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import useMapViewerReducer from '../hooks/useMapViewerReducer';
import api from './api';
import {
  actionSearchBegin,
  actionSearchError,
  actionSearchSuccess
} from './state/actions';
import initialState from './state/initialState';
import reducer from './state/reducer';

export const MapViewerContext = React.createContext(null);

const MapViewerProvider = ({ children }) => {
  const [state, dispatch] = useMapViewerReducer(reducer, initialState);

  useEffect(() => {
    const doSearch = async () => {
      const term = state.search.term;
      const searchOptions = state.searchOptions;
      try {
        dispatch(actionSearchBegin(term, searchOptions));
        const results = await api.search({ term, searchOptions });
        dispatch(actionSearchSuccess(results));
      } catch (err) {
        dispatch(actionSearchError(err));
      }
    };
    doSearch();

    return () => {
      api.cancel();
    };
  }, [dispatch]);

  return (
    <MapViewerContext.Provider value={[state, dispatch]}>
      {children}
    </MapViewerContext.Provider>
  );
};

/**
 * TODO Document me.
 */
MapViewerProvider.propTypes = {
  children: PropTypes.node,
};

MapViewerProvider.defaultProps = {
  children: null,
};

export default MapViewerProvider;
