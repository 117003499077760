import React from 'react';
import Value from './Value';

const formatStreetLine2 = (city, state, zipCode) => {
  const parts = [];
  if (city) {
    parts.push(`${city},`);
  }
  parts.push(state, zipCode);
  return parts.join(' ');
};

const ValueAddress = ({ entity }) => {
  const { street, city, state, zipCode, island } = entity;
  return (
    <>
      <Value fontSize={14}>{street || ' '}</Value>
      <Value fontSize={14}>{formatStreetLine2(city, state, zipCode)}</Value>
      {island && <Value fontSize={14}>{island.name}</Value>}
    </>
  );
};

export default ValueAddress;
