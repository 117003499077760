import { singleClick } from 'ol/events/condition';
import Select from 'ol/interaction/Select';
import React, {
  useEffect,
  useImperativeHandle,
  useRef
} from 'react';
import useMap from '../../../map/context/useMap';

const SingleClickSelectInteraction = React.forwardRef(({ onSelect }, ref) => {
  const { map } = useMap();
  const selectRef = useRef(null);

  /**
   * Setup the select interaction.
   */
  useEffect(() => {
    if (!map) {
      return;
    }

    selectRef.current = new Select({
      condition: singleClick,
      style: null,
    });

    selectRef.current.on('select', e => onSelect(e));
    map.addInteraction(selectRef.current);

    return () => {
      if (selectRef.current) {
        map.removeInteraction(selectRef.current);
      }
    };
  }, [map, selectRef]);

  /**
   * Allow to clear
   */
  useImperativeHandle(ref, () => ({
    clear: () => {
      if (selectRef.current) {
        selectRef.current.getFeatures().clear();
      }
    }
  }), [selectRef]);

  return null;
});

export default SingleClickSelectInteraction;
