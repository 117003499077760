import produce from 'immer';
import {
  ACTION_SET_MAP,
} from './actions-types';

/**
 * State reducer.
 *
 * @param draft {object} an Immer draft state object.
 * @param action {object} the action to reduce state with.
 * @returns {object} new state.
 */
const reducer = produce((draft, action = {}) => {
  const { type, payload } = action;

  switch (type) {

    case ACTION_SET_MAP:
      const { map } = payload;
      draft.map = map;
      return;

    default:
      return;
  }
});

export default reducer;
