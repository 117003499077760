import React from 'react';
import useMapViewer from '../context/useMapViewer';
import EmptyResults from './EmptyResults';
import SearchError from './SearchError';
import SearchResultItem from './SearchResultItem';
import WindowedSearchResults from './WindowedSearchResults';

const SearchResults = () => {
  const { search: { results, error, term, isSearchResultEmpty }, selection: { index } } = useMapViewer();

  if (isSearchResultEmpty) {
    return (
      <EmptyResults term={term} />
    );
  }

  if (error) {
    return (
      <SearchError error={error} />
    );
  }

  return (
    <WindowedSearchResults
      results={results}
      isSearchResultEmpty={isSearchResultEmpty}
      scrollToIndex={index}
      renderItem={({ item, index, style }) => (
        <SearchResultItem item={item} key={index} style={style} index={index} />
      )} />
  );
};

export default SearchResults;
