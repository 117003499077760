import { Button } from "@modernary/ui-kit-core";
import React from "react";
import ToolPanels from "../../../constants/ToolPanels";
import useMapViewer from "../../context/useMapViewer";
import formatNumber from "../../util/formatNumber";

const ShowResultsButton = () => {
  const {
    search: { results },
    setActivePanel,
  } = useMapViewer();

  const handleOnClick = () => setActivePanel(ToolPanels.SearchResults);

  return (
    <Button
      onClick={handleOnClick}
      variant="small"
      fill="solid"
      baseColor="primary"
      size="small"
      style={{ width: "100%" }}
    >
      Search Results ({formatNumber(results.length)})
    </Button>
  );
};

export default ShowResultsButton;
