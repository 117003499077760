import axios from "axios";
import React, { useDeferredValue, useState } from "react";
import DetailMapInsert from "./components/DetailMapInsert";
import DetailRow from "./components/DetailRow";
import DetailSourceSystemLink from "./components/DetailSourceSystemLink";
import { facilitySiteOwnershipTypes } from "../../../constants/NpdesEnums";
import EntityTypes from "../../../constants/EntityTypes";

const NpdesFacilitiesDetail = ({ entity }) => {
  const [sicCode, setSicCode] = useState("");
  const [naicsCode, setNaicsCode] = useState("");

  let index = entity.sicCodes?.findIndex((x) => x.isPrimary) ?? -1;
  if (index > -1) {
    axios
      .get(EntityTypes.npdesFacilities.sicEndpoint + "/" + entity.sicCodes[index].sicId)
      .then((result) => {
        setSicCode(`${result.data.code} - ${result.data.description}`);
      });
  } else if (sicCode !== "") {
    setSicCode("");
  }

  index = entity.naicsCodes?.findIndex((x) => x.isPrimary) ?? -1;
  if (index > -1) {
    axios
      .get(EntityTypes.npdesFacilities.naicsEndpoint + "/" + entity.naicsCodes[index].naicsId)
      .then((result) => {
        setNaicsCode(`${result.data.code} - ${result.data.description}`);
      });
  } else if (naicsCode !== "") {
    setNaicsCode("");
  }

  return (
    <>
      <DetailMapInsert entity={entity} />
      <DetailRow
        label="Ownership Type"
        value={facilitySiteOwnershipTypes.get(entity.ownershipType)?.description}
      />
      <DetailRow label="Primary SIC" value={sicCode} />
      <DetailRow label="Primary NAICS" value={naicsCode} />
      <DetailRow label="Tax Map Key" value={entity.taxMapKey} />
      <DetailSourceSystemLink entity={entity} />
    </>
  );
};

export default NpdesFacilitiesDetail;
