import React from 'react';
import styled from "@emotion/styled/macro";
import { css } from '@emotion/core';
import { Link } from "react-router-dom";
import { colorMap, typeScaleMap } from '../theme/themeMapping';
import Color from 'color';
import { HeadingBase, getLineHeight } from '../theme/commonType';
import Chevron from './Chevron';
import { flexbox } from '../styleFunctions/StyleFunctions';

const paddingHStandard = 26;
const paddingVStandard = 22;
const borderRadius = 5;
const borderWidth = 1;
const bgColor = colorMap.cardBgColor;
const bgColorActive = colorMap.transparentHighlight;
const highlight = colorMap.cardHighlight;
const boxShadowColor = Color(highlight).fade(.7);
const boxShadow = `0px 0px 8px ${boxShadowColor}`;
const boxShadowActive = `0px 0px 5px ${boxShadowColor}`;
const headingFontSize = typeScaleMap.cardHeading;
const bodyFontSize = typeScaleMap.cardBody;


// padding-top: ${(inputHeight - inputFontSize - inputBorderWidth) / 2}px;
// padding-bottom: ${(inputHeight - inputFontSize - inputBorderWidth) / 2}px;

export const CardStyleInner = props => css`
  padding-top: ${props.paddingV != null ? props.paddingV : paddingVStandard}px;
  padding-bottom: ${props.paddingV != null ? props.paddingV : paddingVStandard}px;
  padding-left: ${props.paddingH != null ? props.paddingH : paddingHStandard}px;
  padding-right: ${props.paddingH != null ? props.paddingH : paddingHStandard}px;
  font-size: ${bodyFontSize}px;
  background: ${bgColor};
  color: ${colorMap.normalText};
`

export const CardStyleBase = props => css`
  display: block;
  border: ${borderWidth}px solid transparent;
  border-radius: ${borderRadius}px;
  cursor: pointer;
  transition: 90ms ease;
  box-shadow: ${boxShadow};
  &, &:focus {
    outline: 0;
    /* box-shadow: none; */
  }
  &:hover, &:focus {
    /* background-color: ${bgColorActive}; */
    /* border-color: ${highlight}; */
    box-shadow: ${boxShadowActive};
    transform: translateY(-1px);
  }
`

const CardUI = styled.div`
  ${CardStyleBase}
  ${CardStyleInner}
`;

export const CardHeader = styled.div`
  display: flex;
  align-items: center;
`

export const CardHeaderItem = styled.div`
  ${flexbox}
  &:not(:last-child) {
    margin-right: ${headingFontSize * .75}px; 
  }
`

export const CardHeading = styled(HeadingBase)`
  flex: 1;
  font-size: ${headingFontSize}px;
  font-weight: 600;
  color: ${colorMap.heading};
`

export const CardInner = styled.div`
  ${CardStyleInner}
`

export const CardBody = styled.div`
  &:not(:first-of-type) {
    padding-top: ${props => props.paddingV != null ? props.paddingV * (2/3) : paddingVStandard * (2/3)}px;
  }
`

export default function Card ({ children, ...props }) {
  const element = props.to ? Link :
    props.action ? "button" : "div"
  return (
    <CardUI {...props} as={element}>
      {children}
    </CardUI>
  )
}