import React from 'react';
import DetailRow from './DetailRow';
import ValueAddress from './ValueAddress';

const DetailAddress = ({ entity }) => {
  return (
    <DetailRow>
      <ValueAddress entity={entity} />
    </DetailRow>
  );
};

export default DetailAddress;
