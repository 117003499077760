import styled from '@emotion/styled';
import { position } from 'styled-system';
import { layout } from '../../styleFunctions/StyleFunctions';

const MapContainer = styled.div`
  ${position}
  ${layout}
`;

export default MapContainer;
