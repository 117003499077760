import { Box, Text } from "@modernary/ui-kit-core";
import React from "react";
import EntityTypes from "../../../../constants/EntityTypes";
import { colorMap } from "../../../../theme/themeMapping";

const DetailTitle = ({ entity, rootMarginBottom = "medium" }) => {
  return (
    <Box mb={rootMarginBottom}>
      <Text mb="xxsmall" fontSize={12} color={EntityTypes[entity.extra.type].colorMarker}>
        {EntityTypes[entity.extra.type].label}
      </Text>
      <Text fontWeight={600} fontSize={16} color={colorMap.heading}>
        {entity.extra.title}
      </Text>
    </Box>
  );
};

export default DetailTitle;
