import { Box } from "@modernary/ui-kit-core";
import { getCenter } from "ol/extent";
import WKT from "ol/format/WKT";
import React, { useEffect, useState } from "react";
import EntityTypes from "../../../constants/EntityTypes";
import useMapViewer from "../../context/useMapViewer";
import DetailTitle from "../../flyout/detail/components/DetailTitle";
import ValueAddress from "../../flyout/detail/components/ValueAddress";
import extentEntityLocations from "../util/extentEntityLocations";
import Overlay from "./Overlay";

const format = new WKT();

const EntityOverlay = () => {
  const {
    selection: { item: entity, clickCoordinates },
    deselectResultItem,
  } = useMapViewer();
  const [position, setPosition] = useState(null);

  /**
   * Update position when entity changes.
   */
  useEffect(() => {
    if (!entity) {
      setPosition(undefined);
      return;
    }

    if (clickCoordinates) {
      setPosition(clickCoordinates);
    } else if (entity.extra.hasLocation) {
      if (entity.locations[0].centroid) {
        const point = format.readGeometryFromText(entity.locations[0].centroid, {
          dataProjection: "EPSG:4326",
          featureProjection: "EPSG:3857",
        });
        setPosition(point.getCoordinates());
      } else {
        const extent = extentEntityLocations(entity);
        setPosition(getCenter(extent));
      }
    } else {
      setPosition(undefined);
    }
  }, [entity, clickCoordinates]);

  const style = {
    borderTopWidth: 2,
    borderTopColor: entity && EntityTypes[entity.extra.type].colorMarker,
    width: 320,
  };

  const title = entity ? <DetailTitle entity={entity} rootMarginBottom={0} /> : null;

  return (
    <Overlay position={position} style={style} title={title} onAfterClose={deselectResultItem}>
      {entity && (
        <Box p="small">
          <ValueAddress entity={entity} />
        </Box>
      )}
    </Overlay>
  );
};

export default EntityOverlay;
