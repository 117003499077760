import React from 'react';
import DetailMapInsert from './components/DetailMapInsert';
import DetailRow from './components/DetailRow';
import DetailSourceSystemLink from './components/DetailSourceSystemLink';

const WaterQualityAdvisoryDetail = ({ entity }) => {
  return (
    <>
      <DetailRow label="Location" value={entity.locationName} vertical />
      <DetailMapInsert entity={entity} />
      <DetailRow label="Description" value={entity.description} vertical />
      <DetailRow label="Posted Date" value={entity.postedDate} />
      <DetailRow label="Closed Date" value={entity.closedDate} />
      <DetailRow label="Status" value={entity.status} />
      <DetailSourceSystemLink entity={entity} />
    </>
  );
};

export default WaterQualityAdvisoryDetail;
