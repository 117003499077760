import { Flex } from "@modernary/ui-kit-core";
import React from "react";
import ShowResultsButton from "./ShowResultsButton";

const MeasurePanel = ({}) => {
  return (
    <Flex row gutter="medium" m="medium">
      <Flex.Item>
        <ShowResultsButton />
      </Flex.Item>
      <Flex.Item>Measure!</Flex.Item>
    </Flex>
  );
};

export default MeasurePanel;
