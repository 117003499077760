import React from 'react';
import ArcGISFeatureService from '../../../map/layer/ArcGISFeatureService';
import useMapViewer from '../../context/useMapViewer';

const ReferenceLayers = ({}) => {
  const { map: { referenceLayers } } = useMapViewer();

  return referenceLayers.map(layer => (
    <ArcGISFeatureService
      key={layer.id}
      url={layer.url}
      layerId={layer.layerId}
      color={layer.color}
      minZoom={layer.minZoom}
      extraProperties={{ layer: { ...layer } }}
    />
  ));
};

export default ReferenceLayers;
