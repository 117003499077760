import axios, { CancelToken } from "axios";
import isArray from "lodash/isArray";
import ExtraProps from "./functions/ExtraProps";
import EntityTypes from "../../../constants/EntityTypes";

const makeGet = ({ entityType, term, cancelToken, searchOptions }) => ({
  params: {
    maxResults: searchOptions.maxResults,
    take: searchOptions.maxResults, // uic facilities only
    ...entityType.defaultParams,
    keywords: term,
  },
  cancelToken: cancelToken,
});

export const isOk = (response) => response && response.status === 200;

const spreadExtraProps = (entityType, results, response) => {
  let list = response.data.list ? response.data.list : response.data.value;
  return list.forEach((entity) => {
    if (EntityTypes.hwHandlers.id === entityType.id) {
      //hw is a little funky because it doesn't return an array of locations, we need to make that ourselves.
      if (!entity.locationLongitude || !entity.locationLatitude) return [];

      if (!entity.locations) {
        var result = {
          id: entity.id,
          geometry: `POINT (${entity.locationLongitude} ${entity.locationLatitude})`,
          centroid: `POINT (${entity.locationLongitude} ${entity.locationLatitude})`,
          //locationType?
        };
        entity.locations = [result];
      }
    }
    results.push({
      ...entity,
      extra: {
        type: ExtraProps[entityType.id].type,
        title: ExtraProps[entityType.id].title(entity),
        description: ExtraProps[entityType.id].description(entity),
        status: ExtraProps[entityType.id].status(entity),
        url: ExtraProps[entityType.id].url(entity),
        hasLocation: isArray(entity.locations) && entity.locations.length > 0,
      },
    });
  });
};

const compareName = (a, b) => {
  if (a.extra.title < b.extra.title) {
    return -1;
  }
  if (a.extra.title > b.extra.title) {
    return 1;
  }
  return 0;
};

const cancelTokens = [];

const cancelSearch = () => {
  cancelTokens.forEach((cancel) => {
    cancel();
  });
};

export default {
  /**
   * Cancel search.
   */
  cancel: () => cancelSearch(),

  /**
   * Search all endpoints at once.
   *
   * https://www.storyblok.com/tp/how-to-send-multiple-requests-using-axios
   *
   * @param term
   * @returns {Promise<[]>}
   */
  search: async ({ term, searchOptions }) => {
    cancelSearch();

    const cancelExecutor = (c) => {
      cancelTokens.push(c);
    };

    const errorHandler = (endpoint) => (err) => {
      console.warn(`Unable to call endpoint [${endpoint}]: ${err.message}`);
      return null;
    };

    const requests = [];

    const newGet = (entityType) => {
      return axios
        .get(
          entityType.endpoint,
          makeGet({
            searchOptions: searchOptions,
            entityType: entityType,
            term,
            cancelToken: new CancelToken(cancelExecutor),
          })
        )
        .catch(errorHandler(entityType.endpoint));
    };

    searchOptions.entityTypes.forEach((entityType) => {
      requests.push(newGet(entityType));
    });

    const responses = await axios.all(requests);
    const results = [];

    searchOptions.entityTypes.forEach((entityType, index) => {
      if (isOk(responses[index])) {
        spreadExtraProps(entityType, results, responses[index]);
      }
    });

    results.sort(compareName);

    return results;
  },
};
