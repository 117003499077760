import moment from "moment";
import EntityTypes from "../../../../constants/EntityTypes";
import { hwCounties, hwGenerators } from "../../../../constants/HwEnums";

const getLocalMoment = (date) => {
  if (date && typeof date === "string" && date.length > 10 && !date.toString().endsWith("Z")) {
    date += "Z";
  }
  return moment(date);
};

const islandName = (entity) => (entity.island ? entity.island.name : "");

export default {
  [`${EntityTypes.emergencyReleases.id}`]: {
    type: "emergencyReleases",
    title: (entity) => `${entity.releaseName} (${entity.caseNumber})`,
    description: (entity) => `${islandName(entity)} - ${entity.summary}`,
    status: (entity) =>
      "Reported On " + getLocalMoment(entity.reportedOn).format("MM/D/YY h:mm A zz"),
    url: (entity) => `https://eha-cloud.doh.hawaii.gov/iheer/#!/incident/${entity.id}/details/view`,
  },
  [`${EntityTypes.waterQualityAdvisories.id}`]: {
    type: "waterQualityAdvisories",
    title: (entity) => entity.title,
    description: (entity) => `${islandName(entity)} - ${entity.description || ""}`,
    status: (entity) => "Posted On " + getLocalMoment(entity.postedOn).format("ll"),
    url: (entity) => `https://eha-cloud.doh.hawaii.gov/cwb/#!/event/${entity.id}/details/view`,
  },
  [`${EntityTypes.uicFacilities.id}`]: {
    type: "uicFacilities",
    title: (entity) => {
      if (
        entity.affiliatedOrganizations &&
        entity.affiliatedOrganizations.length > 0 &&
        entity.affiliatedOrganizations[0].facility
      ) {
        return entity.affiliatedOrganizations[0].facility.name;
      }
      return entity.name;
    },
    description: (entity) => {
      if (!entity._operatedBy) {
        if (
          entity.affiliatedOrganizations &&
          entity.affiliatedOrganizations.length > 0 &&
          entity.affiliatedOrganizations[0].organization
        ) {
          entity._operatedBy = " - " + entity.affiliatedOrganizations[0].organization.name;
        } else {
          entity._operatedBy = "";
        }
      }
      if (!entity._locatedAt) {
        if (entity.street && entity.city && entity.zipCode) {
          entity._locatedAt =
            " - located at " + entity.street + " " + entity.city + " " + entity.zipCode;
        } else {
          entity._locatedAt = "";
        }
      }
      return islandName(entity) + entity._operatedBy + entity._locatedAt;
    },
    status: (entity) => "Last Updated On " + getLocalMoment(entity.updatedOn).format("ll"),
    url: (entity) => `https://eha-cloud.doh.hawaii.gov/sdwb/#!/facility/${entity.id}/details/view`,
  },
  [`${EntityTypes.remediationSites.id}`]: {
    type: "remediationSites",
    title: (entity) => entity.name,
    description: (entity) => {
      if (!entity._locatedAt) {
        if (entity.street && entity.city && entity.zipCode) {
          entity._locatedAt =
            " - located at " + entity.street + " " + entity.city + " " + entity.zipCode;
        } else if (entity.locationDescription) {
          entity._locatedAt = " - located at " + entity.locationDescription;
        } else {
          entity._locatedAt = "";
        }
      }
      return islandName(entity) + entity._locatedAt;
    },
    status: (entity) => "Last Updated On " + getLocalMoment(entity.updatedOn).format("ll"),
    url: (entity) => `https://eha-cloud.doh.hawaii.gov/iheer/#!/site/${entity.id}/details/view`,
  },
  [`${EntityTypes.airPollutionSources.id}`]: {
    type: "airPollutionSources",
    title: (entity) => entity.name + " (" + entity.number + ")",
    description: (entity) => {
      if (!entity._permittee) {
        if (
          entity.affiliatedOrganizations &&
          entity.affiliatedOrganizations.length > 0 &&
          entity.affiliatedOrganizations[0].organization
        ) {
          entity._permittee = " - " + entity.affiliatedOrganizations[0].organization.name;
        } else {
          entity._permittee = "";
        }
      }
      if (!entity._locatedAt) {
        if (entity.street && entity.city && entity.zipCode) {
          entity._locatedAt =
            " - located at " + entity.street + " " + entity.city + " " + entity.zipCode;
        } else {
          entity._locatedAt = "";
        }
      }
      return islandName(entity) + entity._permittee + entity._locatedAt;
    },
    status: (entity) => "Last Updated On " + getLocalMoment(entity.updatedOn).format("ll"),
    url: (entity) =>
      `https://eha-cloud.doh.hawaii.gov/cab/#!/facilitySite/${entity.id}/details/view`,
  },
  [`${EntityTypes.hwHandlers.id}`]: {
    type: "hwHandlers",
    title: (entity) => `${entity.number} - ${entity.name}`,
    description: (entity) => {
      if (!entity._generator) {
        entity._generator = hwGenerators.get(entity.federalWasteGenerator)?.longDescription;
        entity._generator = entity._generator ? ` - ${entity._generator}` : "";
      }
      if (!entity._locatedAt) {
        if (entity.locationStreet1 && entity.locationCity && entity.locationZip) {
          entity._locatedAt = ` - located at ${entity.locationStreet1} ${entity.locationCity} ${entity.locationZip}`;
        } else {
          entity._locatedAt = "";
        }
      }
      if (!entity._islandName) entity._islandName = hwCounties.get(entity.countyCode)?.description;

      return entity._islandName + entity._generator + entity._locatedAt;
    },
    status: (entity) =>
      "Received " + getLocalMoment(entity.receiveDate).format("MM/DD/YY h:mm A zz"),
    url: (entity) => `https://eha-cloud.doh.hawaii.gov/hw/handler/${entity.id}/view`,
  },
  [`${EntityTypes.npdesFacilities.id}`]: {
    type: "npdesFacilities",
    title: (entity) => entity.name,
    description: (entity) => {
      if (!entity._locatedAt) {
        if (entity.street && entity.city && entity.state && entity.zipCode) {
          entity._locatedAt = ` - located at ${entity.street} ${entity.city} ${entity.state} ${entity.zipCode}`;
        } else {
          entity._locatedAt = ` - located at ${entity.locationDescription}`;
        }
      }

      return `${islandName(entity)} ${entity._locatedAt}`;
    },
    status: (entity) => "Last Updated " + getLocalMoment(entity.updatedOn).format("ll"),
    url: (entity) =>
      `https://eha-cloud.doh.hawaii.gov/cwb/#!/facilitySite/${entity.id}/details/view`,
  },
};
