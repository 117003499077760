import styled from '@emotion/styled/macro';
import { css } from '@emotion/core';
import { colors } from "../theme/theme";
import { colorMap } from '../theme/themeMapping';

export const inputPaddingH = 20;
export const inputPaddingV = 12;
export const inputFontSize = 16;
export const inputBorderRadius = 5;
export const inputBorderWidth = 1;
export const backgroundColor = 'white';

// padding-top: ${(inputHeight - inputFontSize - inputBorderWidth) / 2}px;
// padding-bottom: ${(inputHeight - inputFontSize - inputBorderWidth) / 2}px;

export const InputStyleBase = props => css`
  width: 100%;
  padding-top: ${inputPaddingV}px;
  padding-bottom: ${inputPaddingV}px;
  padding-left: ${inputPaddingH}px;
  padding-right: ${inputPaddingH}px;
  border: ${inputBorderWidth}px solid ${colorMap.inputBorder};
  font-size: ${inputFontSize}px;
  line-height: 1em;
  background: ${backgroundColor};
  border-radius: ${inputBorderRadius}px;
  color: ${colorMap.normalText};
  &, &:focus {
    outline: 0;
    box-shadow: none;
  }
`
export const InputStyleFocus = () => css`
  border-color: ${colorMap.activeHighlight};
`

export const InputStyle = styled.input`
  ${InputStyleBase}
  &:focus {
    ${InputStyleFocus}
  }
`