export const hwCounties = new Map([
  [
    "HI001",
    {
      id: "HI001",
      description: "Hawaii",
    },
  ],
  [
    "HI003",
    {
      id: "HI003",
      description: "Honolulu",
    },
  ],
  [
    "HI005",
    {
      id: "HI005",
      description: "Kalawao",
    },
  ],
  [
    "HI007",
    {
      id: "HI007",
      description: "Kauai",
    },
  ],
  [
    "HI009",
    {
      id: "HI009",
      description: "Maui",
    },
  ],
]);

export const hwGenerators = new Map([
  [
    "1",
    {
      id: "1",
      description: "LQG",
      longDescription: "Large Quantity Generator",
      isFilter: true,
    },
  ],
  [
    "2",
    {
      id: "2",
      description: "SQG",
      longDescription: "Small Quantity Generator",
      isFilter: true,
    },
  ],
  [
    "3",
    {
      id: "3",
      description: "VSQG",
      longDescription: "Very Small Quantity Generator",
      isFilter: true,
    },
  ],
  [
    "U",
    {
      id: "U",
      description: "Undetermined",
      longDescription: "Undetermined",
      isFilter: false,
    },
  ],
  [
    "N",
    {
      id: "N",
      description: "Not Generator",
      longDescription: "Not Generator",
      isFilter: true,
    },
  ],
  [
    "P",
    {
      id: "P",
      description: null,
      longDescription: null,
      isFilter: false,
    },
  ],
]);

export const handlerSourceTypes = new Map([
  [
    "A",
    {
      id: "A",
      description: "Part A",
    },
  ],
  [
    "B",
    {
      id: "B",
      description: "Annual/Biennial Report update with Notification",
    },
  ],
  [
    "D",
    {
      id: "D",
      description: "Deactivation",
    },
  ],
  [
    "E",
    {
      id: "E",
      description: "Emergency",
    },
  ],
  [
    "I",
    {
      id: "I",
      description: "Implementer",
    },
  ],
  [
    "K",
    {
      id: "K",
      description: "Electronic Manifest Broker",
    },
  ],
  [
    "N",
    {
      id: "N",
      description: "Notification",
    },
  ],
  [
    "R",
    {
      id: "R",
      description: "Annual/Biennial Report",
    },
  ],
  [
    "T",
    {
      id: "T",
      description: "Temporary",
    },
  ],
]);
