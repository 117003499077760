import { Flex } from "@modernary/ui-kit-core";
import React from "react";
import Label from "./Label";
import Value from "./Value";

const LABEL_WIDTH = 120;

const DetailRow = ({ label, value, vertical, children, labelProps }) => {
  return (
    <Flex column={!vertical} row={vertical} mb="small" gutter={vertical ? "xsmall" : "xsmall"}>
      {label && (
        <Flex.Item width={!vertical ? LABEL_WIDTH : undefined} flex={vertical ? 1 : undefined}>
          <Label {...labelProps}>{label}</Label>
        </Flex.Item>
      )}
      <Flex.Item flex={1}>
        {children && children}
        {!children && <Value>{value}</Value>}
      </Flex.Item>
    </Flex>
  );
};

export default DetailRow;
