import { colors, typeScale, spacing } from './theme';
import Color from 'color';

export const colorMap = {
  normalText: colors.veryDarkBlue,
  highlightText: colors.turquoise,
  boldText: colors.darkGreen,
  diminishedText: colors.mediumGray,
  heading: colors.darkBlue,
  subduedHeading: colors.fadedBlue,
  activeHighlight: colors.electricBlue,
  link: colors.electricBlue,
  linkHover: Color(colors.electricBlue).lighten(.2).string(),
  inputBorder: colors.violet,
  cardBorder: 'transparent',
  cardBgColor: 'white',
  cardHighlight: colors.electricBlue,
  cardText: colors.darkBlue,
  listBackground: colors.nearWhite,
  transparentHighlight: Color(colors.electricBlue).saturationl(100).lightness(98).string(),
  // searchBar: "#f0f5fc",
  searchBar: "#dfecff",
  lightSeparator: 'gainsboro',
}
colorMap.cardActive = Color(colorMap.cardHighlight).fade(.8).toString();
colorMap.cardHover = Color(colorMap.cardHighlight).fade(.9).toString();

export const bgElevations = {
  top: '#ffffff',
  b1: 'hsl(215, 67%, 98%)',
  b2: 'hsl(214, 4%, 95%)',
}
export const typeScaleMap = {
  h1: typeScale[7],
  h2: typeScale[6],
  h3: typeScale[5],
  h4: typeScale[3],
  h5: typeScale[1],
  h6: typeScale[0],
  cardHeading: 18,
  cardBody: 14,
  listSearchHeadings: typeScale[1],
}
export const lineHeightMap = {
  body: 1.42,
  h1: 1.35,
  h2: 1.35,
  h3: 1.35,
  h4: 1.35,
  h5: 1.35,
  h6: 1.35,
}
export const layoutSpacing = {
  screenEdgeMarginH: 32,
  listPaddingH: 24,
  listPaddingV: 32,
}