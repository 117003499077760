import React from "react";
import styled from "@emotion/styled/macro";
import { colorMap, layoutSpacing, typeScaleMap } from "../theme/themeMapping";
import { Heading4, CutoffText, DiminishedText } from "../theme/commonType";
import ItemPanel from "./ItemPanel";
import { FlexContainer, FlexItem } from "./CommonLayout";
import { Button, HollowBlueButton } from "./Buttons";

//margin: 0 ${layoutSpacing.listPaddingH}px;
const ListItemPadV = 20;
// const ListItem = styled(ItemPanel)`
//   padding: ${ListItemPadV}px ${layoutSpacing.listPaddingH}px 0;
// `
const ListItemStyle = styled(ItemPanel)`
  padding: ${ListItemPadV}px ${layoutSpacing.listPaddingH}px;
  border: 1px solid gainsboro;
  margin: 12px 0;
  border-radius: 5px;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.13);
  border-color: transparent;
  &:first-of-type {
    margin-top: 0;
  }
`;
export const ListItemTitle = styled(Heading4)`
  font-size: ${typeScaleMap.listSearchHeadings}px;
  font-weight: 600;
  margin-bottom: 12px;
`;
export const ListItemText = styled.div`
  font-size: 14px;
  color: ${colorMap.normalText};
  margin: 0;
`;
export const ListItemActions = styled.div`
  display: flex;
  justify-content: flex-end;
  &:not(:first-of-type) {
    margin-top: 12px;
  }
`;

const SearchListSequence = styled.div`
  /* & > *:not(:last-child) {
    margin-bottom: 16px;
  } */
`;

const StatusLabel = styled.span`
  background-color: #ccc;
  color: white;
  text-transform: uppercase;
  letter-spacing: 0.06em;
  font-size: 12px;
  font-weight: 500;
  padding: 3px 5px;
  border-radius: 3px;
`;

export const PostedDate = styled.span`
  font-weight: 600;
`;
const InactiveDate = styled(DiminishedText)`
  font-weight: 500;
`;

function ListItem({ item, ...props }) {
  return (
    <ListItemStyle {...props}>
      <FlexContainer alignItems="baseline">
        <FlexItem>
          <ListItemTitle>{item.searchTitleName}</ListItemTitle>
        </FlexItem>
        <FlexItem auto>
          <PostedDate>{item.searchStatusText}</PostedDate>
        </FlexItem>
      </FlexContainer>
      <ListItemText>
        <CutoffText isCutoff={!props.isExpanded}>{item.searchDescriptionText}</CutoffText>
      </ListItemText>
      {props.isExpanded && (
        <ListItemActions>
          <HollowBlueButton
            as="a"
            fontSize={0}
            buttonSpacing={2}
            target="_blank"
            href={item.entityUrl}
          >
            View in program system
          </HollowBlueButton>
        </ListItemActions>
      )}
    </ListItemStyle>
  );
}

const MemoizedItem = React.memo(ListItem);

export const SearchListItems = (props) => {
  return (
    <SearchListSequence>
      {props.results &&
        props.results.map((item, index) => (
          <MemoizedItem
            key={item.id + props.selectedCategory}
            last={index === props.results.length - 1}
            clickable
            // onClick={() => window.location = item.entityUrl}
            onClick={() => props.handleItemClick(item.id)}
            isExpanded={props.expandedItemId === item.id}
            item={item}
          />
        ))}
    </SearchListSequence>
  );
};
