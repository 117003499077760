import styled from "@emotion/styled";
import { createTheme, ThemeProvider } from "@modernary/ui-kit-core";
import React from "react";
import SmallerHeader from "./components/SmallerHeader";
import EntityTypes from "./constants/EntityTypes";
import MapViewerProvider from "./map-viewer/context/MapViewerProvider";
import MapFeatureClickListener from "./map-viewer/map/interaction/MapFeatureClickListener";
import SelectReferenceLayerFeatureInteraction from "./map-viewer/map/interaction/SelectReferenceLayerFeatureInteraction";
import BaseLayer from "./map-viewer/map/layer/BaseLayer";
import EntityFeaturesLayer from "./map-viewer/map/layer/EntityFeaturesLayer";
import MyLocationLayer from "./map-viewer/map/layer/MyLocationLayer";
import ReferenceLayers from "./map-viewer/map/layer/ReferenceLayers";
import EntityOverlay from "./map-viewer/map/overlay/EntityOverlay";
import ReferenceLayerFeatureOverlay from "./map-viewer/map/overlay/ReferenceLayerFeatureOverlay";
import ViewerMap from "./map-viewer/map/ViewerMap";
import MapOmniBox from "./map-viewer/MapOmniBox";

const Root = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;
const Header = styled.div`
  flex: 0;
`;
const Body = styled.div`
  flex: 1;
`;

const options = {
  fonts: {
    sans: "AvenirNext",
    serif: "serif",
    monospace: "monospace",
  },
};

const MapView = () => {
  return (
    <ThemeProvider theme={createTheme(options)}>
      <MapViewerProvider>
        <Root>
          <Header>
            <SmallerHeader />
          </Header>
          <Body>
            <ViewerMap>
              <BaseLayer />
              <ReferenceLayers />
              <EntityFeaturesLayer entityType={EntityTypes.emergencyReleases.id} />
              <EntityFeaturesLayer entityType={EntityTypes.waterQualityAdvisories.id} />
              <EntityFeaturesLayer entityType={EntityTypes.uicFacilities.id} />
              <EntityFeaturesLayer entityType={EntityTypes.remediationSites.id} />
              <EntityFeaturesLayer entityType={EntityTypes.airPollutionSources.id} />
              <EntityFeaturesLayer entityType={EntityTypes.hwHandlers.id} />
              <EntityFeaturesLayer entityType={EntityTypes.npdesFacilities.id} />
              <MyLocationLayer />
              <SelectReferenceLayerFeatureInteraction />
              <EntityOverlay />
              <ReferenceLayerFeatureOverlay />
              <MapFeatureClickListener />
              <MapOmniBox />
            </ViewerMap>
          </Body>
        </Root>
      </MapViewerProvider>
    </ThemeProvider>
  );
};

export default MapView;
