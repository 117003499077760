import React from 'react';
import Layers, { LayerTypes } from '../../../constants/Layers';
import ArcGISTileLayer from '../../../map/layer/ArcGISTileLayer';
import OpenStreetMapTileLayer from '../../../map/layer/OpenStreetMapTileLayer';
import useMapViewer from '../../context/useMapViewer';

const BaseLayer = () => {
  const { map: { baseLayer } } = useMapViewer();

  return Layers.BaseLayers.map(layer => {
    switch (layer.type) {
      case LayerTypes.OpenStreetMap:
        return (
          <OpenStreetMapTileLayer
            key={layer.id}
            opacity={1}
            visible={layer.id === baseLayer.id}
          />
        );
      case LayerTypes.ArcGIS:
        return (
          <ArcGISTileLayer
            key={layer.id}
            opacity={1}
            visible={layer.id === baseLayer.id}
            url={layer.url}
            attributions={layer.attributions}
          />
        );
      default:
        return null;
    }
  });
};

export default BaseLayer;
