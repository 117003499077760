import React, { useState, useRef, useEffect } from 'react';
import styled from '@emotion/styled/macro';
import Color from 'color';
import WrappedInlineSvg from './WrappedInlineSvg';
import { InputStyle, InputStyleBase, InputStyleFocus } from './InputStyle';
import { colorMap } from '../theme/themeMapping';
import { SearchConsumer } from '../context/SearchContext';
import searchSvg from '../commonImages/search.svg';

const variants = {
  large: {
    searchFontSize: 24,
    searchPaddingH: 20,
    searchPaddingV: 12,
    iconSize: 28,
  },
  block: {
    searchFontSize: 24,
    searchPaddingH: 16,
    searchPaddingV: 14,
    iconSize: 18,
    borderRadius: 5,
    backgroundColor: colorMap.searchBar,
    placeholderColor: Color('#719dff').fade(.5).string(),
    border: 0
  },
  default: {
    backgroundColor: InputStyle.backgroundColor,
    placeholderColor: '#719dff',
    iconColor: colorMap.inputBorder,
  }
}
const getVariantProp = (propName, variant) => {
  const target = variants[variant][propName];
  return target !== undefined ? target : variants.default[propName];
}

const SearchInputIconStyle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: ${props => props.leftOffset}px;
  margin-right: ${props => props.leftOffset}px;
  width: ${props => props.size}px;
  height: ${props => props.size}px;
  color: ${props => props.isActive ? colorMap.activeHighlight : getVariantProp('iconColor', props.variant)};
`

const SearchInputIcon = (props) => (  
  <SearchInputIconStyle variant={props.variant} isActive={props.isActive} size={props.size} leftOffset={props.paddingH}>
    {props.children}
  </SearchInputIconStyle>
)

const SearchInput = styled.input`
  ${InputStyleBase}
  border: 0;
  background: none;
  width: 100%;
  font-weight: 500;
  font-size: ${props => getVariantProp('searchFontSize', props.variant)}px;
  padding-top: ${props => getVariantProp('searchPaddingV', props.variant)}px;
  padding-bottom: ${props => getVariantProp('searchPaddingV', props.variant)}px;
  padding-left: 0;
  padding-right: ${props => getVariantProp('searchPaddingH', props.variant)}px;
  & ::placeholder {
    color: ${props => getVariantProp('placeholderColor', props.variant)};
  }
`;

const SearchInputWrap = styled(InputStyle)`
  ${InputStyleBase}
  padding: 0;
  width: 100%;
  display: flex;
  align-items: center;
  background-color: ${props => getVariantProp('backgroundColor', props.variant)};
  ${props => props.isActive && InputStyleFocus}
  ${props => {
    const br = getVariantProp('borderRadius', props.variant);
    return br !== undefined ? `border-radius: ${br}px;` : ''}
  }
  ${props => {
    const b = getVariantProp('border', props.variant);
    return b !== undefined ? `border: ${b}px` : ''}
  }
`

// const BlockSearchBarWrap = styled(SearchInputWrap)

// export const BlockSearchBar = ({inputComponent: }) => <SearchBar 

const SearchBar = (props) => {
  const [focus, setFocus] = useState(false);
  const inputRef = useRef();

  useEffect(() => {
    if (props.autoFocus) inputRef.current.focus();
  }, [props.autoFocus])
  
  const isActive = focus || props.styleActive;
  const paddingH = props.paddingH != null ? props.paddingH : getVariantProp('searchPaddingH', props.variant);
  return (
    <SearchConsumer>
      {({ query, updateQuery }) => (
        <form onSubmit={props.onSubmit ? e => props.onSubmit(e) : e => { e.preventDefault(); }}>
          <SearchInputWrap as="div" isActive={isActive} variant={props.variant}>
            <SearchInputIcon
              variant={props.variant}
              size={getVariantProp('iconSize', props.variant)}
              paddingH={paddingH}
              isActive={isActive}>
              <WrappedInlineSvg src={searchSvg}></WrappedInlineSvg>
            </SearchInputIcon>
            <SearchInput
              variant={props.variant}
              ref={inputRef}
              autoFocus={props.autoFocus}
              placeholder={props.placeholder}
              onFocus={() => {props.onFocus && props.onFocus(); setFocus(true)}}
              onBlur={() => {props.onBlur && props.onBlur(); setFocus(false)}}
              onChange={(event) => {updateQuery(event.currentTarget.value)}}
              isActive={isActive}
              value={query || ''}
            ></SearchInput>
          </SearchInputWrap>
        </form>
      )}
    </SearchConsumer>
  )
}
SearchBar.defaultProps = {
  variant: 'large'
}

export default SearchBar;