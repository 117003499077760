import { Icons } from "@modernary/ui-kit-core";
import React from "react";
import MapConstants from "../../../constants/MapConstants";
import useMap from "../../../map/context/useMap";
import { toWebMercatorExtent } from "../../../map/util/transform";
import useMapViewer from "../../context/useMapViewer";
import ToolBarItem from "./ToolBarItem";

const HomeToolBarItem = () => {
  const { map } = useMap();
  const { reset } = useMapViewer();

  const handleOnClick = async () => {
    if (!map) {
      return;
    }

    map.getView().fit(toWebMercatorExtent(MapConstants.extent), {
      padding: [0, 0, 0, 0],
      duration: 1000,
    });

    await reset();
  };

  return <ToolBarItem iconName={Icons.Home} onClick={handleOnClick} title="Reset the map" />;
};

export default HomeToolBarItem;
