import {
  alignContent,
  alignItems,
  alignSelf,
  compose,
  display,
  flex,
  flexBasis,
  flexDirection,
  flexWrap,
  height,
  justifyContent,
  justifyItems,
  justifySelf,
  maxHeight,
  maxWidth,
  minHeight,
  minWidth,
  order,
  overflow,
  verticalAlign,
  width,
} from 'styled-system';

export const flexbox = compose(
  alignContent,
  alignItems,
  alignSelf,
  flex,
  flexBasis,
  flexDirection,
  flexWrap,
  justifyContent,
  justifyItems,
  justifySelf,
  order,
);

export const layout = compose(
  display,
  height,
  maxHeight,
  maxWidth,
  minHeight,
  minWidth,
  overflow,
  verticalAlign,
  width
);
