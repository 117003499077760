import { Flex } from "@modernary/ui-kit-core";
import React from "react";
import HelpToolBarItem from "./buttons/HelpToolBarItem";
import HomeToolBarItem from "./buttons/HomeToolBarItem";
import LayersToolBarItem from "./buttons/LayersToolBarItem";
import MyLocationToolBarItem from "./buttons/MyLocationToolBarItem";

const ToolBar = () => {
  return (
    <Flex column gutter="xxsmall" alignItems="center" m="small" bg="blue.600" p="xxsmall">
      <HomeToolBarItem />
      <MyLocationToolBarItem />
      <LayersToolBarItem />
      <HelpToolBarItem />
    </Flex>
  );
};

export default ToolBar;
