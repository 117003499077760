import { BorderBox, Box, Button, Flex, Icons } from "@modernary/ui-kit-core";
import React from "react";
import EntityTypes from "../../../constants/EntityTypes";
import useMapViewer from "../../context/useMapViewer";
import AirPollutionSourceDetail from "./AirPollutionSourceDetail";
import DetailAddress from "./components/DetailAddress";
import DetailTitle from "./components/DetailTitle";
import EmergencyReleaseDetail from "./EmergencyReleaseDetail";
import RemediationSiteDetail from "./RemediationSiteDetail";
import UicFacilityDetail from "./UicFacilityDetail";
import WaterQualityAdvisoryDetail from "./WaterQualityAdvisoryDetail";
import HwHandlersDetail from "./HwHandlersDetail";
import NpdesFacilitiesDetail from "./NpdesFacilitiesDetail";

const EntityDetail = ({ entity }) => {
  const { deselectResultItem } = useMapViewer();

  if (!entity) {
    return null;
  }

  let entityComponent = <div>Entity not supported: {entity.extra.type}</div>;

  switch (entity.extra.type) {
    case EntityTypes.airPollutionSources.id:
      entityComponent = <AirPollutionSourceDetail entity={entity} />;
      break;
    case EntityTypes.uicFacilities.id:
      entityComponent = <UicFacilityDetail entity={entity} />;
      break;
    case EntityTypes.remediationSites.id:
      entityComponent = <RemediationSiteDetail entity={entity} />;
      break;
    case EntityTypes.emergencyReleases.id:
      entityComponent = <EmergencyReleaseDetail entity={entity} />;
      break;
    case EntityTypes.waterQualityAdvisories.id:
      entityComponent = <WaterQualityAdvisoryDetail entity={entity} />;
      break;
    case EntityTypes.hwHandlers.id:
      entityComponent = <HwHandlersDetail entity={entity} />;
      break;
    case EntityTypes.npdesFacilities.id:
      entityComponent = <NpdesFacilitiesDetail entity={entity} />;
      break;
    default:
      break;
  }

  const handleOnClickClose = () => {
    deselectResultItem();
  };

  return (
    <BorderBox borderTopWidth={2} borderTopColor={EntityTypes[entity.extra.type].colorMarker}>
      <Flex
        column
        bg={EntityTypes[entity.extra.type].colorBg}
        mb="large"
        pt="small"
        pr="small"
        gutter="xxsmall"
      >
        <Flex.Item flex={1} pl="medium" pt="medium">
          <DetailTitle entity={entity} />
          <DetailAddress entity={entity} />
        </Flex.Item>
        <Flex.Item flex={0}>
          <Button
            baseColor="grey"
            icon={Icons.X}
            fill="ghost"
            shape="round"
            onClick={handleOnClickClose}
          />
        </Flex.Item>
      </Flex>
      <Box p="small" pl="medium" pt={0} pb="large">
        {entityComponent}
      </Box>
    </BorderBox>
  );
};

export default EntityDetail;
