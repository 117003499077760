import React from 'react';
import MapConstants from '../../constants/MapConstants';
import Map from '../../map/Map';
import useMapViewer from '../context/useMapViewer';

const ViewerMap = ({ children }) => {
  const { map: { maxZoom } } = useMapViewer();

  return (
    <Map
      extent={MapConstants.extent}
      id="hi-map"
      width="100%"
      height="100%"
      maxZoom={maxZoom}
    >
      {children}
    </Map>
  );
};

export default ViewerMap;
