import WKT from 'ol/format/WKT';
import {
  Fill,
  Icon,
  Stroke,
  Style
} from 'ol/style';
import PropTypes from 'prop-types';
import React, {
  useEffect,
  useState
} from 'react';
import EntityTypes from '../../../constants/EntityTypes';
import MapConstants from '../../../constants/MapConstants';
import useMap from '../../../map/context/useMap';
import FeaturesLayer from '../../../map/layer/FeaturesLayer';
import useMapViewer from '../../context/useMapViewer';

const isEntityType = (entityType, entity) => entity.extra.type === entityType;

const hasLocation = entity => {
  if (Array.isArray(entity.locations)) {
    return entity.locations.length > 0;
  }
  return false;
};

const format = new WKT();

const EntityFeaturesLayer = ({ entityType }) => {
  const { map } = useMap();
  const { search: { results }, selection: { hasSelection, item: entity, entityLocation } } = useMapViewer();
  const [features, setFeatures] = useState([]);

  const style = new Style({
    image: new Icon({
      color: EntityTypes[entityType].colorMarker,
      crossOrigin: 'anonymous',
      src: 'images/dot.png',
    }),
    stroke: new Stroke({
      color: 'rgba(0, 100, 0, 0.9)',
      width: 3
    }),
    fill: new Fill({
      color: 'rgba(0, 255, 20, 0.2)'
    })
  });

  /**
   * Setup features when search results are updated.
   */
  useEffect(() => {
    const features = [];

    results.forEach((entity, index) => {
      if (isEntityType(entityType, entity) && hasLocation(entity)) {
        entity.locations.forEach(location => {
          const feature = format.readFeature(location.geometry, {
            dataProjection: 'EPSG:4326',
            featureProjection: 'EPSG:3857'
          });
          feature.setProperties({ entity, index, entityLocation: location });
          feature.setId(`${entity.id}-${location.id}`);
          feature.setStyle(style);
          features.push(feature);
        });
      }
    });

    setFeatures(features);
  }, [results]);

  /**
   * When entity is selected pan to it's location.
   */
  useEffect(() => {
    if (!entity) {
      return;
    }

    if (entity.extra.type !== entityType) {
      return;
    }

    if (entity.extra.hasLocation) {
      const geometry = (entityLocation && entityLocation.geometry) || entity.locations[0].geometry;

      const feature = format.readFeatureFromText(geometry, {
        dataProjection: 'EPSG:4326',
        featureProjection: 'EPSG:3857'
      });

      const resolution = map.getView().getResolutionForZoom(map.getView().getZoom());

      map.getView().fit(feature.getGeometry().getExtent(), {
        padding: [0, 0, 0, MapConstants.omniBox.width * 2],
        duration: 1000,
        minResolution: resolution
      });
    }

  }, [entity, hasSelection, map]);

  return (
    <FeaturesLayer id={entityType} features={features} zIndex={2000} />
  );
};

EntityFeaturesLayer.propTypes = {
  entityType: PropTypes.string.isRequired,
};

export default EntityFeaturesLayer;
