import { layoutSpacing, colorMap, bgElevations, typeScaleMap } from "./theme/themeMapping";
import styled from "@emotion/styled/macro";
import { Column } from "./components/CommonLayout";
import css from "@emotion/css";
import { Heading4 } from "./theme/commonType";

const ShadowBoxStyle = () => css`
  position: relative;
  z-index: 1;
`;

const ShadowBoxBottomStyle = () => css`
  ${ShadowBoxStyle()}
  box-shadow: 0px 2px 3px -1px rgba(0,0,0,.17);
`;

const ShadowBoxRightStyle = () => css`
  ${ShadowBoxStyle()}
  box-shadow: 2px 0px 3px -1px rgba(0,0,0,.17);
`;

export const ShadowBoxBottom = styled.div`
  ${ShadowBoxBottomStyle}
`;

export const ShadowBoxRight = styled.div`
  ${ShadowBoxRightStyle}
`;

export const HeaderContainer = styled.div`
  border-bottom: 2px solid ${colorMap.activeHighlight};
`;

export const ListColumn = styled(Column)`
  ${ShadowBoxRightStyle}
  max-width: ${({ fillWidth }) => fillWidth ? 'none' : '300px'};
`;

export const ResultsColumn = styled(Column)`
  overflow-x: hidden;
  overflow-y: auto;
  ${props => props.usePadding && `
    padding-top: ${layoutSpacing.listPaddingV}px;
    padding-bottom: ${layoutSpacing.listPaddingV}px;
    padding-left: ${layoutSpacing.listPaddingH}px;
    padding-right: ${layoutSpacing.listPaddingH}px;
  `}
  background-color: ${bgElevations.b1};
`;

export const ListContainer = styled.div`
  width: 100%;
  flex: 1;
  position: relative;
  /* background-color: ${colorMap.listBackground}; */
  /* padding: ${layoutSpacing.listPaddingV}px ${layoutSpacing.listPaddingH}px; */
`;

export const ResultsCountBox = styled.div`
  padding: 24px 0;
  font-size: ${typeScaleMap.listSearchHeadings}px;
  color: ${colorMap.subduedHeading};
`;

export const SelectACategoryPrompt = styled(Heading4)`
  padding: ${layoutSpacing.listPaddingV}px ${layoutSpacing.listPaddingH}px;
  color: ${colorMap.subduedHeading};
`;

export const MatchedQuery = styled.span`
  font-weight: 600;
  /* font-style: italic; */
`;
