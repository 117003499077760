/**
 * Initial state to give the map viewer reducer.
 */
import FlyoutPanels from '../../../constants/FlyoutPanels';
import MapConstants from '../../../constants/MapConstants';
import ToolPanels from '../../../constants/ToolPanels';

export default {
  /**
   * Search state.
   */
  search: {
    countInProgress: 0,
    isSearching: false,
    error: undefined,
    term: MapConstants.defaultSearchTerm,
    results: [],
  },

  /**
   * Search options state.
   */
  searchOptions: MapConstants.defaultSearchOptions,

  /**
   * Selected result item.
   */
  selection: {
    index: undefined,
    item: undefined,
    hasSelection: false,
  },

  /**
   * Map state.
   */
  map: {
    baseLayer: MapConstants.defaultBaseLayer,
    referenceLayers: [],
    maxZoom: MapConstants.defaultBaseLayer.maxZoom,
  },

  /**
   * Reference layers feature selection.
   */
  referenceLayerFeature: {
    feature: undefined,
  },

  /**
   * OmniBox state.
   */
  omniBox: {
    panel: ToolPanels.SearchResults,
    isFlyoutOpen: false,
    flyOutPanel: FlyoutPanels.SearchOptions
  }
};
