/**
 *  Private helper to create actions with a consistent shape. The shape is
 *  inspired by the redux community.
 *
 *  Ref: https://shorturl.at/cdgNW
 *
 */
import { ACTION_SET_MAP } from './actions-types';

const action = (type, payload) => {
  return {
    type: type,
    payload: {
      ...payload,
    },
  };
};

export const actionSetMap = map => action(ACTION_SET_MAP, { map });
