import React from 'react';
import styled from '@emotion/styled/macro';
import { CenterColumnWrap, CenterColumn, Columns, Column } from './CommonLayout';
import { colors } from '../theme/theme';
import SplitWithChildMargin from './SplitWithChildMargin';
import { HollowBlueButton } from './Buttons';
import AppBrand from './AppBrand';
import { layoutSpacing } from '../theme/themeMapping';


export const headerTheme = {
  background: 'white',
  outerPaddingV: 32,
  outerPaddingVSmall: 24,
}

const HeaderOuter = styled.header`
  background-color: ${headerTheme.background};
  padding: ${headerTheme.outerPaddingV}px 0;
`

export const OrgHeadingText = styled.span`
  color: ${colors.darkGreen};
`

function LandingHeader () {
  return (
    <HeaderOuter>
      <CenterColumnWrap gutter={layoutSpacing.screenEdgeMarginH}>
        <CenterColumn>
          <Columns gutter={20}>
            <Column>
              <OrgHeadingText>Hawaii State Environmental Health Administration</OrgHeadingText>
              <AppBrand large>EHA<strong>Connect</strong></AppBrand>
            </Column>
            <Column flex="0 0 auto">
              {/* <HollowBlueButton fontSize={0} buttonSpacing={1}>Admin Login</HollowBlueButton> */}
            </Column>
          </Columns>
        </CenterColumn>
      </CenterColumnWrap>
    </HeaderOuter>
  );
}

export default LandingHeader;
