import { Icons } from "@modernary/ui-kit-core";
import React from "react";
import ToolPanels from "../../../constants/ToolPanels";
import useMap from "../../../map/context/useMap";
import useMapViewer from "../../context/useMapViewer";
import ToolBarItem from "./ToolBarItem";

const HelpToolBarItem = () => {
  const { map } = useMap();
  const { setActivePanel } = useMapViewer();

  const handleOnClick = async () => {
    if (!map) {
      return;
    }

    setActivePanel(ToolPanels.Help);
  };

  return <ToolBarItem iconName={Icons.Info} onClick={handleOnClick} title="See help information" />;
};

export default HelpToolBarItem;
