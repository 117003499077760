const LayerTypes = {
  OpenStreetMap: 'osm',
  ArcGIS: 'arcgis'
};

export {
  LayerTypes
};

export default {
  BaseLayers: [
    {
      id: 1,
      type: 'osm',
      title: 'Open Street Maps',
      description: 'description',
      thumbnail: 'images/preview/osm.png',
      maxZoom: 20
    },
    {
      id: 2,
      type: 'arcgis',
      url: 'https://services.arcgisonline.com/ArcGIS/rest/services/World_Street_Map/MapServer',
      title: 'World Streets',
      attributions: 'Esri, HERE, Garmin, USGS, Intermap, INCREMENT P, NRCan, Esri Japan, METI, Esri China (Hong Kong), Esri Korea, Esri (Thailand), NGCC, (c) OpenStreetMap contributors, and the GIS User Community',
      thumbnail: 'images/preview/world-street.png',
      maxZoom: 18
    },
    {
      id: 9,
      type: 'arcgis',
      url: [
        'http://services.arcgisonline.com/arcgis/rest/services/World_Imagery/MapServer',
        'http://services.arcgisonline.com/arcgis/rest/services/Reference/World_Boundaries_and_Places/MapServer'
      ],
      title: 'World Imagery',
      attributions: 'Esri, DigitalGlobe, GeoEye, Earthstar Geographics, CNES/Airbus DS, USDA, USGS, AeroGRID, IGN, and the GIS User Community',
      thumbnail: 'images/preview/world-imagery.png',
      maxZoom: 18
    },
    {
      id: 10,
      type: 'arcgis',
      url: [
        'http://services.arcgisonline.com/arcgis/rest/services/Elevation/World_Hillshade/MapServer',
        'http://services.arcgisonline.com/arcgis/rest/services/World_Topo_Map/MapServer'
      ],
      title: 'World Topographical',
      attributions: 'Esri, HERE, Garmin, Intermap, increment P Corp., GEBCO, USGS, FAO, NPS, NRCAN, GeoBase, IGN, Kadaster NL, Ordnance Survey, Esri Japan, METI, Esri China (Hong Kong), (c) OpenStreetMap contributors, and the GIS User Community',
      thumbnail: 'images/preview/world-topo.png',
      maxZoom: 18
    },
    {
      id: 3,
      type: 'arcgis',
      url: [
        'https://services.arcgisonline.com/arcgis/rest/services/Ocean/World_Ocean_Base/MapServer',
        'https://services.arcgisonline.com/arcgis/rest/services/Ocean/World_Ocean_Reference/MapServer'
      ],
      title: 'World Ocean',
      attributions: 'Esri, Garmin, GEBCO, NOAA NGDC, and other contributors',
      thumbnail: 'images/preview/world-ocean.png',
      maxZoom: 12
    },
    {
      id: 4,
      type: 'arcgis',
      url: [
        'https://services.arcgisonline.com/arcgis/rest/services/Canvas/World_Light_Gray_Base/MapServer',
        'https://services.arcgisonline.com/arcgis/rest/services/Canvas/World_Light_Gray_Reference/MapServer'
      ],
      title: 'World Light Gray',
      attributions: 'Esri, HERE, Garmin, (c) OpenStreetMap contributors, and the GIS user community',
      thumbnail: 'images/preview/world-light-gray.png',
      maxZoom: 15
    },
    {
      id: 5,
      type: 'arcgis',
      url: [
        'https://services.arcgisonline.com/arcgis/rest/services/Canvas/World_Dark_Gray_Base/MapServer',
        'https://services.arcgisonline.com/arcgis/rest/services/Canvas/World_Dark_Gray_Reference/MapServer',
      ],
      title: 'World Dark Gray',
      attributions: 'Esri, HERE, Garmin, (c) OpenStreetMap contributors, and the GIS user community',
      thumbnail: 'images/preview/world-dark-gray.png',
      maxZoom: 15
    },
    {
      id: 6,
      type: 'arcgis',
      url: [
        'http://services.arcgisonline.com/arcgis/rest/services/Specialty/DeLorme_World_Base_Map/MapServer',
      ],
      title: 'DeLorne',
      attributions: 'Copyright:(c) 2018 Garmin',
      thumbnail: 'images/preview/delorne.png',
      maxZoom: 12
    },
    {
      id: 7,
      type: 'arcgis',
      url: [
        'http://services.arcgisonline.com/arcgis/rest/services/USA_Topo_Maps/MapServer',
      ],
      title: 'USA Topographical',
      attributions: 'Copyright:© 2013 National Geographic Society, i-cubed',
      thumbnail: 'images/preview/usa-topo.png',
      maxZoom: 16
    },
    {
      id: 8,
      type: 'arcgis',
      url: [
        'http://services.arcgisonline.com/arcgis/rest/services/NatGeo_World_Map/MapServer',
      ],
      title: 'National Geographic',
      attributions: 'National Geographic, Esri, Garmin, HERE, UNEP-WCMC, USGS, NASA, ESA, METI, NRCAN, GEBCO, NOAA, increment P Corp.',
      thumbnail: 'images/preview/nat-geo.png',
      maxZoom: 17
    }
  ],
  ReferenceLayers: [
    {
      id: 0,
      type: 'arcgis',
      url: 'https://geodata.hawaii.gov/arcgis/rest/services/ParcelsZoning/MapServer',
      layerId: 25,
      title: 'Statewide TMKs',
      minZoom: 9,
      zIndex: 1000,
      color: 'rgba(54, 168, 186, 0.4)'
    },
  ]
};
