import { Box, Icon, Icons } from "@modernary/ui-kit-core";
import React from "react";
import SearchInput from "./SearchInput";

const SearchBar = () => {
  return (
    <Box p="small">
      <SearchInput />
    </Box>
  );
};

export default SearchBar;
