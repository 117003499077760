import styled from "@emotion/styled";
import { BorderBox, Flex } from "@modernary/ui-kit-core";
import PropTypes from "prop-types";
import React from "react";
import { ListItemText } from "../../components/SearchListItems";
import EntityTypes from "../../constants/EntityTypes";
import { colorMap } from "../../theme/themeMapping";
import EntityIndicator from "../components/EntityIndicator";
import useMapViewer from "../context/useMapViewer";

const Root = styled(BorderBox)`
  width: 100%;
  height: 100%;
  border-left: 3px solid transparent;
  transition: background-color 0.3s ease, border-left 0.3s ease;
  overflow: hidden;

  &:hover {
    background-color: #ccdefb;
    cursor: pointer;
    border-left: 3px solid #689cf3;
  }
`;

const RootSelected = styled(Root)`
  background-color: ${({ isSelected, entityBg }) => (isSelected ? entityBg : undefined)};
  border-left-width: 3px;
  border-left-color: ${({ isSelected, entityBorderColor }) =>
    isSelected ? entityBorderColor : undefined};
  transition: background-color 0.3s ease, border-left 0.3s ease;

  &:hover {
    background-color: #bad2fa;
    cursor: pointer;
    border-left: 3px solid #689cf3;
  }
`;

export const Title = styled.div`
  margin: 0;
  line-height: 1.35em;
  color: ${colorMap.heading};
  font-size: 15px;
  font-weight: 600;
`;

const truncate = (str, len = 20, ellipsis = "...") => {
  if (!str) {
    return;
  }
  if (str.length > len) {
    return `${str.substring(0, len - ellipsis.length - 1)} ${ellipsis}`;
  }
  return str;
};

const SearchResultItem = ({ index: listIndex, item: entity, style }) => {
  const {
    selectResultItem,
    deselectResultItem,
    selection: { index: selectedIndex },
  } = useMapViewer();

  const isSelected = listIndex === selectedIndex;

  const handleOnClick = () => {
    isSelected ? deselectResultItem() : selectResultItem({ index: listIndex, item: entity });
  };

  const RootComponent = isSelected ? RootSelected : Root;

  return (
    <RootComponent
      style={style}
      p="medium"
      borderBottomColor="#efefef"
      borderBottomWidth={1}
      onClick={handleOnClick}
      isSelected={isSelected}
      entityBorderColor={entity && EntityTypes[entity.extra.type].colorMarker}
      entityBg={entity && EntityTypes[entity.extra.type].colorBg}
    >
      <Flex column gutter="small">
        <Flex.Item flex={1} mb="small">
          <Title>{truncate(entity.extra.title, 32)}</Title>
        </Flex.Item>
        {entity.extra.hasLocation && (
          <Flex.Item ml={4} flex={0}>
            <EntityIndicator entity={entity} size={18} />
          </Flex.Item>
        )}
      </Flex>
      <ListItemText>{truncate(entity.extra.description, 120)}</ListItemText>
    </RootComponent>
  );
};

SearchResultItem.propTypes = {
  item: PropTypes.object.isRequired,
};

export default SearchResultItem;
