import {
  createEmpty,
  extend
} from 'ol/extent';
import WKT from 'ol/format/WKT';
import {
  Fill,
  Icon,
  Stroke,
  Style,
} from 'ol/style';
import PropTypes from 'prop-types';
import React, {
  useEffect,
  useState
} from 'react';
import EntityTypes from '../../../constants/EntityTypes';
import useMap from '../../../map/context/useMap';
import FeaturesLayer from '../../../map/layer/FeaturesLayer';
import extentEntityLocations from '../util/extentEntityLocations';

const hasLocation = entity => {
  if (Array.isArray(entity.locations)) {
    return entity.locations.length > 0;
  }
  return false;
};

const format = new WKT();

const EntityLayer = ({ entity }) => {
  const { map } = useMap();
  const [features, setFeatures] = useState([]);

  const entityType = entity.extra.type;

  const style = new Style({
    image: new Icon({
      color: EntityTypes[entityType].colorMarker,
      crossOrigin: 'anonymous',
      src: 'images/dot.png',
    }),
    stroke: new Stroke({
      color: 'rgba(0, 100, 0, 0.9)',
      width: 3
    }),
    fill: new Fill({
      color: 'rgba(0, 255, 20, 0.2)'
    })
  });

  /**
   * Add features.
   */
  useEffect(() => {
    if (!hasLocation(entity)) {
      return;
    }

    const features = [];

    entity.locations.forEach(location => {
      const feature = format.readFeature(location.geometry, {
        dataProjection: 'EPSG:4326',
        featureProjection: 'EPSG:3857'
      });
      feature.setId(`${entity.id}-${location.id}`);
      feature.setStyle(style);
      features.push(feature);
    });

    setFeatures(features);
  }, [entity]);

  /**
   * Zoom to entity
   */
  useEffect(() => {
    if (!map) {
      return;
    }

    if (!entity) {
      return;
    }

    if (!entity.extra.hasLocation) {
      return;
    }

    let ext = extentEntityLocations(entity);
    const resolution = map.getView().getResolutionForZoom(map.getView().getZoom());
    const padding = 15;

    map.getView().fit(ext, {
      padding: [padding, padding, padding, padding],
      duration: 1000,
      minResolution: resolution
    });

  }, [features]);

  return (
    <FeaturesLayer id={entityType} features={features} />
  );
};

EntityLayer.propTypes = {
  entity: PropTypes.object.isRequired,
};

export default EntityLayer;
