import { Box } from "@modernary/ui-kit-core";
import React from "react";
import { HollowBlueButton } from "../../../../components/Buttons";
import EntityTypes from "../../../../constants/EntityTypes";
import { colors } from "../../../../theme/theme";

// This is for the HollowBlueButton because child
// themeProvider (EHA > Windsor UI-KIT) can't access
// parent themeprovider values.
const style = {
  borderColor: colors.electricBlue,
  color: colors.electricBlue,
};

const DetailSourceSystemLink = ({ entity }) => {
  const url = EntityTypes[entity.extra.type].getSourceSystemLink(entity);
  return (
    <Box textAlign="center" mt="large" mb="xlarge">
      <HollowBlueButton
        as="a"
        fontSize={0}
        buttonSpacing={2}
        target="_blank"
        href={url}
        style={style}
      >
        View in program system
      </HollowBlueButton>
    </Box>
  );
};

export default DetailSourceSystemLink;
