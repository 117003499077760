import React from 'react';
import DetailMapInsert from './components/DetailMapInsert';
import DetailRow from './components/DetailRow';
import DetailSourceSystemLink from './components/DetailSourceSystemLink';

const getOperatorName = entity => {
  if (entity.affiliatedOrganizations && entity.affiliatedOrganizations.length > 0) {
    return entity.affiliatedOrganizations[0].organization.name;
  }
};

const UicFacilityDetail = ({ entity }) => {
  return (
    <>
      <DetailMapInsert entity={entity} />
      <DetailRow label="Operated By" value={getOperatorName(entity)} />
      <DetailRow label="Status" value={entity.status} />
      <DetailSourceSystemLink entity={entity} />
    </>
  );
};

export default UicFacilityDetail;
