import { Text } from "@modernary/ui-kit-core";
import moment from "moment";
import React from "react";
import { colorMap } from "../../../../theme/themeMapping";

const utcDateRegex = /^\d{4}-\d{2}-\d{2}/;
const isDate = (str) => utcDateRegex.test(str);

const formatValue = (value) => {
  if (isDate(value)) {
    return moment(value).format("ll");
  }
  return value;
};

const Value = ({ children, fontSize }) => {
  if (!children) {
    return (
      <Text fontSize={fontSize || 13} fontWeight="normal" color={colorMap.normalText}>
        &#8212;
      </Text>
    );
  }
  return (
    <Text fontSize={fontSize || 13} fontWeight="normal" color={colorMap.normalText}>
      {formatValue(children)}
    </Text>
  );
};

export default Value;
