export const facilitySiteOwnershipTypes = new Map([
  ["CTG", { id: "CTG", value: "CTG", description: "City Government", isActive: true }],
  ["FED", { id: "FED", value: "FED", description: "Federal", isActive: true }],
  ["IND", { id: "IND", value: "IND", description: "Industrial", isActive: true }],
  ["MIG", { id: "MIG", value: "MIG", description: "Migrated", isActive: false }],
  ["MS4", { id: "MS4", value: "MS4", description: "MS4", isActive: true }],
  ["MUN", { id: "MUN", value: "MUN", description: "Municipal", isActive: true }],
  ["MWD", { id: "MWD", value: "MWD", description: "Municipal or Water District", isActive: true }],
  ["POF", { id: "POF", value: "POF", description: "Privately Owned Facility", isActive: true }],
  ["STF", { id: "STF", value: "STF", description: "State Government", isActive: true }],
]);
