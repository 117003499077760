import { ThemeProvider } from 'emotion-theming';
import React from 'react';
import { Route } from 'react-router-dom';
import { MainContextProvider } from './context/MainContext';
import { SearchProvider } from './context/SearchContext';
import MainView from './MainView';
import MapView from './MapView';
import { ContextualSearchView } from './SearchView';
import theme from './theme/theme';
import { Global } from '@emotion/core';
import GlobalStyles from './style/GlobalStyles';

// const redirectIndex = {
//   searchCategory: (id) => `/search/${id}`
// }

function App(props) {
  // const [redirectPath, setRedirectPath] = useState();
  // const doRedirect = (index, ...args) => setRedirectPath(redirectIndex[index](...args));

  return (
    <ThemeProvider theme={theme}>
      <Global
        styles={GlobalStyles}
      />
      <MainContextProvider>
        <SearchProvider>
          {/* {redirectPath
           ? (
           <Redirect to={redirectPath} />
           )
           : ( */}
          <>
            <Route component={MainView} path="/" exact />
            <Route component={MapView} path="/map" exact />
            <Route component={ContextualSearchView}
                   path="/search/:category?"
              // navigateToCategory={(cat) => doRedirect('searchCategory', cat)}
            />
          </>
        </SearchProvider>
        {/* )} */}
      </MainContextProvider>
    </ThemeProvider>
  );
}

export default App;
