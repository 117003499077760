/**
 * Returns whether or not the search result is empty.
 *
 * @param term the search term.
 * @param results the search results.
 * @returns {boolean} whether or not the search result is empty.
 */
export default (term, results) => {
  if (!term) {
    return false;
  }

  return (term.length > 0 && results.length === 0);
};
