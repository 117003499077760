import React from 'react';
import SvgWrap from './SvgWrap';

const Chevron = (props) => {
  const {color, ...rest} = props;
  return (
    <SvgWrap {...rest} color={color || 'currentColor'} stroke="true">
      <svg x="0px" y="0px"
        width="1em" height="1em" viewBox="0 0 16 16">
        <polyline fill="none" stroke="inherit" strokeMiterlimit="10" points="6,2 12,8 6,14 " />
      </svg>
    </SvgWrap>
  )
};

export default Chevron;