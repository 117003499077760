import React, { useContext } from 'react';
import styled from '@emotion/styled/macro';
import { css } from '@emotion/core';
import { spacing } from '../theme/theme';
import { flexbox } from '../styleFunctions/StyleFunctions';

const centerColumnMaxWidth = 1200;
const centerColumnGutter = spacing.medium;

export const breakAtMQ = width => `
  @media screen and (max-width: ${width - 1}px) {
    flex-direction: column;
    justify-content: flex-start;
  }
`

export const SplitWrap = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

export const Rows = styled.div`
  display: flex;
  flex-direction: column;
`

const getColumnsMargin = ({ gutter, flush }) => {
  return (flush  
    ? gutter 
      ? -1 * gutter / 2
      : 0
    : gutter
  )
}

export const ColumnsDiv = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: ${getColumnsMargin}px;
  margin-right: ${getColumnsMargin}px;
  margin-bottom: -${props => props.breakAt ? (props.gutterV || props.gutter) : 0}px;
  ${props => props.breakAt && breakAtMQ(props.breakAt)}
`

const ColumnsContext = React.createContext();

export const Columns = ({ children, gutter, gutterV, breakAt, ...props }) => {
  gutterV = breakAt ? (gutterV || gutter) : 0;
  return (
    <ColumnsContext.Provider value={{gutter, gutterV}}>
      <ColumnsDiv gutter={gutter} gutterV={gutterV} breakAt={breakAt} {...props}>{children}</ColumnsDiv>
    </ColumnsContext.Provider>
  )
}

Columns.defaultProps = {
  gutter: centerColumnGutter,
  flush: true,
}

const ColumnDiv = styled.div`
  flex: 1 0 0%;
  display: flex;
  flex-direction: column;
  ${flexbox}
  margin-left: ${props => !props.usePadding ? (props.gutter / 2) : 0}px;
  margin-right: ${props => !props.usePadding ? (props.gutter / 2) : 0}px;
  padding-left: ${props => props.usePadding ? (props.gutter / 2) : 0}px;
  padding-right: ${props => props.usePadding ? (props.gutter / 2) : 0}px;
  margin-bottom: ${props => !props.usePadding ? props.gutterV : 0}px;
  padding-bottom: ${props => props.usePadding ? props.gutterV : 0}px;
`

export const Column = ({gutter, gutterV, children, ...props}) => {
  const context = useContext(ColumnsContext);
  return (
    <ColumnDiv
      gutter={gutter != null ? gutter : context.gutter}
      gutterV={gutterV != null ? gutterV : context.gutterV}
      {...props}>
      {children}
    </ColumnDiv>
  )
}

export const NoWrapColumns = styled(Columns)`
  margin-left: 0;
  margin-right: 0;
  margin-bottom: 0;
`

export const CenterColumnWrap = styled(Columns)`
  justify-content: center;
`
CenterColumnWrap.defaultProps = {
  flush: false
}

const CenterColumnDiv = styled(Column)`
  /* max-width: ${props => props.width + (props.gutter || 0)}px; */
  max-width: ${props => props.maxWidth}px;
`
export const CenterColumn = ({ children, maxWidth, ...props }) => {
  return (
    <CenterColumnDiv maxWidth={maxWidth} {...props}>{children}</CenterColumnDiv>
  )
}
CenterColumn.defaultProps = {
  maxWidth: centerColumnMaxWidth,
  // gutter: centerColumnGutter
}

export const FlexContainer = styled.div`
  ${flexbox}
  display: flex;
  flex-direction: ${props => props.vertical ? 'column' : 'row'};
` 

export const FlexItem = styled.div`
  flex: ${props => props.auto ? '0 0 auto' : '1 1 0%'};
  ${flexbox}
`
