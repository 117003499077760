import React, { useContext } from 'react';
import LandingHeader from './components/LandingHeader';
import Hero from './components/Hero';
import { MainContext } from './context/MainContext';

const MainView = () => {
  const {state, dispatch} = useContext(MainContext);

  return (
    <>
      <LandingHeader></LandingHeader>
      {/* <button onClick={() => dispatch({type: 'openSearch'})}>test</button> */}
      <Hero></Hero>
    </>
  );
}

export default MainView;
