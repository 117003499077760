import { lineHeightMap, colorMap } from "../theme/themeMapping";
import { colors } from "../theme/theme";

const GlobalStyles = `
  body {
    line-height: ${lineHeightMap.body};
    color: ${colorMap.normalText};
  }
  .rsm-svg {
    fill: ${colors.electricBlue};
    &:focus, & *:focus {
      outline: 0;
    }
  }
`

export default GlobalStyles;