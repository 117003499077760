import { Box, Link } from "@modernary/ui-kit-core";
import React, { useEffect, useState } from "react";
import { centerOfExtent } from "../../../map/util/transform";
import useMapViewer from "../../context/useMapViewer";
import DetailRow from "../../flyout/detail/components/DetailRow";
import formatNumber from "../../util/formatNumber";
import Overlay from "./Overlay";

const ReferenceLayerFeatureOverlay = () => {
  const {
    referenceLayerFeature: { feature },
    deselectReferenceLayerFeature,
  } = useMapViewer();
  const [position, setPosition] = useState(null);
  const [data, setData] = useState(null);
  const [title, setTitle] = useState(null);

  /**
   * Update position when entity changes.
   */
  useEffect(() => {
    if (!feature) {
      setPosition(undefined);
      setData(undefined);
      return;
    }

    //
    // update data
    //
    const data = feature.getProperties();
    setData(data);

    //
    // update title
    //
    if (feature.get("extra").layer) {
      setTitle(feature.get("extra").layer.title);
    }

    //
    // update position
    //
    const geometry = feature.getGeometry();
    const extent = geometry.getExtent();
    setPosition(centerOfExtent(extent));
  }, [feature]);

  const style = {
    width: 300,
  };

  const gisAcres = data && formatNumber(Number.parseFloat(data["gisacres"]));

  return (
    <Overlay
      position={position}
      title={title}
      style={style}
      onAfterClose={deselectReferenceLayerFeature}
    >
      <Box p="small">
        {data && (
          <>
            <DetailRow label="TMK" value={data["tmk"]} />
            <DetailRow label="Property Size" value={`${gisAcres} acres`} />
            <DetailRow label="Public Record" labelProps={{ pt: 3 }}>
              <Link href={data["qpub_link"]} target="_blank" fontSize={13} mb={2}>
                qPublic.net
              </Link>
            </DetailRow>
          </>
        )}
      </Box>
    </Overlay>
  );
};

export default ReferenceLayerFeatureOverlay;
