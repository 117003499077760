import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import {
  useEffect,
  useRef
} from 'react';
import useMap from '../context/useMap';

const FeaturesLayer = ({ id, zIndex, opacity, visible, features }) => {
  const { map } = useMap();
  const sourceRef = useRef(null);
  const layerRef = useRef(null);

  useEffect(() => {
    if (!map) {
      return;
    }

    sourceRef.current = new VectorSource({
      features: features
    });

    layerRef.current = new VectorLayer({
      id: id,
      zIndex: zIndex,
      opacity: opacity,
      visible: visible,
      source: sourceRef.current
    });

    map.getLayers().push(layerRef.current);


    return () => {
      map.removeLayer(layerRef.current);
    };
  }, [map]);

  useEffect(() => {
    if (sourceRef.current === null) {
      return;
    }

    sourceRef.current.clear();

    if (Array.isArray(features)) {
      features.forEach(f => {
        sourceRef.current.addFeature(f);
      });
    }
  }, [features]);

  return null;
};

export default FeaturesLayer;
