import { Icons } from "@modernary/ui-kit-core";
import React from "react";
import ToolPanels from "../../../constants/ToolPanels";
import useMap from "../../../map/context/useMap";
import useMapViewer from "../../context/useMapViewer";
import ToolBarItem from "./ToolBarItem";

const LayersToolBarItem = () => {
  const { map } = useMap();
  const {
    map: { referenceLayers },
    setActivePanel,
  } = useMapViewer();

  const handleOnClick = async () => {
    if (!map) {
      return;
    }

    setActivePanel(ToolPanels.Layers);
  };

  return (
    <ToolBarItem
      iconName={Icons.Layers}
      onClick={handleOnClick}
      title="Change the layers you see on the map"
      badgeLabel={referenceLayers && referenceLayers.length ? referenceLayers.length : null}
    />
  );
};

export default LayersToolBarItem;
