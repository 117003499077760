import styled from "@emotion/styled/macro";
import { Flex } from "@modernary/ui-kit-core";
import React from "react";
import { ListItemText } from "../../../components/SearchListItems";
import ShowResultsButton from "./ShowResultsButton";

export const HelpHeading1 = styled(ListItemText)`
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 12px;
  margin-top: 8px;
`;
export const HelpHeading2 = styled(ListItemText)`
  font-size: 15px;
  font-weight: 600;
  margin-top: 4px;
  margin-bottom: 4px;
`;
export const HelpText = styled(ListItemText)`
  margin-bottom: 12px;
`;

const HelpPanel = ({}) => {
  return (
    <Flex row gutter="medium" height="100%">
      <Flex.Item flex={0} p="medium">
        <ShowResultsButton />
      </Flex.Item>
      <Flex.Item flex={1} p="medium" style={{ overflow: "hidden", overflowY: "auto" }}>
        <HelpHeading1>Getting Started</HelpHeading1>
        <HelpText>There are two ways to find a site:</HelpText>

        <HelpHeading2>Search</HelpHeading2>
        <HelpText>
          Enter any identifying information into the keyword search, and matching sites will be
          returned to the results list and map, if site locations exist.
        </HelpText>

        <HelpHeading2>Map</HelpHeading2>
        <HelpText>
          Locate sites near a specific area of interest using the move and tools provided on the
          map.
        </HelpText>

        <HelpHeading1>Toolbar</HelpHeading1>

        <HelpHeading2>Home</HelpHeading2>
        <HelpText>Resets the map.</HelpText>

        <HelpHeading2>Location</HelpHeading2>
        <HelpText>Zoom to your location on the map.</HelpText>

        <HelpHeading2>Layers</HelpHeading2>
        <HelpText>Options to change base layers and reference layers.</HelpText>
      </Flex.Item>
    </Flex>
  );
};

export default HelpPanel;
