import React from 'react';
import styled from '@emotion/styled/macro';
import { colors } from '../theme/theme';
import { colorMap } from '../theme/themeMapping';
import { borders, space, color, fontSize, fontWeight } from 'styled-system';
import { buttonSpacing } from '../styleFunctions/ButtonStyleFunctions';

const buttonStyles = {
  borderRadius: 5
}

export const Button = styled.button`
  ${borders}
  ${color}
  ${fontSize}
  ${fontWeight}
  ${buttonSpacing}
  cursor: pointer;
  &:hover, &:focus {
    opacity: .8;
  }
  &:focus {
    outline: 0;
  }
`

export const HollowBlueButton = (props, styleProps) => (
  <Button
    border="1px solid"
    borderColor="electricBlue"
    backgroundColor="transparent"
    color="electricBlue"
    borderRadius={buttonStyles.borderRadius}
    fontWeight="600"
    {...props}
    {...styleProps}>
    {props.children}
  </Button>
)