import produce from 'immer';
import {
  ACTION_CLOSE_FLYOUT_PANEL,
  ACTION_DESELECT_REFERENCE_LAYER_FEATURE,
  ACTION_DESELECT_RESULT_ITEM,
  ACTION_OPEN_FLYOUT_PANEL,
  ACTION_SEARCH_BEGIN,
  ACTION_SEARCH_ERROR,
  ACTION_SEARCH_SUCCESS,
  ACTION_SELECT_REFERENCE_LAYER_FEATURE,
  ACTION_SELECT_RESULT_ITEM,
  ACTION_SET_ACTIVE_TOOL_PANEL,
  ACTION_SET_BASE_LAYER,
  ACTION_SET_REFERENCE_LAYERS
} from './actions-types';
import isEmptyResults from './fp/isEmptyResults';

/**
 * State reducer.
 *
 * @param draft {object} an Immer draft state object.
 * @param action {object} the action to reduce state with.
 * @returns {object} new state.
 */
const reducer = produce((draft, action = {}) => {
  const { type, payload } = action;

  //console.log(`${type}`, payload);

  switch (type) {
    case ACTION_SEARCH_BEGIN:
      const { term, searchOptions } = payload;
      draft.search.term = term;
      draft.search.countInProgress = draft.search.countInProgress + 1;
      draft.search.isSearching = draft.search.countInProgress > 0;
      draft.searchOptions = searchOptions;
      return;
    case ACTION_SEARCH_SUCCESS:
      const { results } = payload;
      draft.search.countInProgress = draft.search.countInProgress - 1;
      // only update results when they're are no searches left in progress.
      draft.search.results = draft.search.countInProgress === 0 ? results : draft.search.results;
      draft.search.isSearchResultEmpty = isEmptyResults(draft.search.term, results);
      draft.search.isSearching = draft.search.countInProgress > 0;
      return;
    case ACTION_SEARCH_ERROR:
      const { error } = payload;
      draft.search.error = error.message;
      draft.search.countInProgress = draft.search.countInProgress - 1;
      draft.search.isSearching = draft.search.countInProgress > 0;
      return;
    case ACTION_SELECT_RESULT_ITEM:
      const { index, item, entityLocation, clickCoordinates } = payload;
      draft.selection.index = index;
      draft.selection.item = item;
      draft.selection.entityLocation = entityLocation;
      draft.selection.clickCoordinates = clickCoordinates;
      draft.selection.hasSelection = true;
      return;
    case ACTION_DESELECT_RESULT_ITEM:
      draft.selection.index = undefined;
      draft.selection.item = undefined;
      draft.selection.hasSelection = false;
      return;
    case ACTION_SET_ACTIVE_TOOL_PANEL:
      draft.omniBox.panel = payload.panel;
      return;
    case ACTION_SET_BASE_LAYER:
      const { layer } = payload;
      draft.map.baseLayer = layer;
      draft.map.maxZoom = layer.maxZoom;
      return;
    case ACTION_SET_REFERENCE_LAYERS:
      const { layers } = payload;
      draft.map.referenceLayers = layers;
      return;
    case ACTION_SELECT_REFERENCE_LAYER_FEATURE:
      const { feature } = payload;
      draft.referenceLayerFeature.feature = feature;
      return;
    case ACTION_DESELECT_REFERENCE_LAYER_FEATURE:
      draft.referenceLayerFeature.feature = undefined;
      return;
    case ACTION_OPEN_FLYOUT_PANEL:
      draft.omniBox.isFlyoutOpen = true;
      draft.omniBox.flyOutPanel = payload.panel;
      return;
    case ACTION_CLOSE_FLYOUT_PANEL:
      draft.omniBox.isFlyoutOpen = false;
      draft.omniBox.flyOutPanel = null;
      return;
    default:
      return;
  }
});

export default reducer;
