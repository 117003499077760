import TileLayer from 'ol/layer/Tile';
import OSM from 'ol/source/OSM';
import PropTypes from 'prop-types';
import {
  useEffect,
  useRef
} from 'react';
import useMap from '../context/useMap';
import randomId from '../util/randomId';

const OpenStreetMapTileLayer = ({ id, zIndex, opacity, visible }) => {
  const { map } = useMap();
  const layerRef = useRef(null);

  useEffect(() => {
    if (!map) {
      return;
    }

    layerRef.current = new TileLayer({
      id: id,
      zIndex: zIndex,
      opacity: opacity,
      visible: visible,
      minZoom: 1,
      maxZoom: 20,
      source: new OSM(),
    });

    map.getLayers().push(layerRef.current);

    return () => {
      map.removeLayer(layerRef.current);
    };
  }, [map]);

  useEffect(() => {
    if (layerRef.current) {
      layerRef.current.setZIndex(zIndex);
      layerRef.current.setOpacity(opacity);
      layerRef.current.setVisible(visible);
    }
  }, [zIndex, opacity, visible]);

  return null;
};

/**
 * TODO Document me
 *
 * https://openlayers.org/en/latest/apidoc/module-ol_layer_Base-BaseLayer.html
 */
OpenStreetMapTileLayer.propTypes = {
  id: PropTypes.any,
  zIndex: PropTypes.number,
  opacity: PropTypes.number,
  visible: PropTypes.bool,
};

OpenStreetMapTileLayer.defaultProps = {
  id: randomId(),
  zIndex: undefined,
  opacity: undefined,
  visible: true,
};

export default OpenStreetMapTileLayer;
