import 'ol/ol.css';
import PropTypes from 'prop-types';
import React from 'react';
import MapStateProvider from './context/MapStateProvider';
import './Map.css';
import MapContainer from './styled/MapContainer';

const Map = ({ children, id, center, extent, zoom, width, height, maxZoom }) => {
  return (
    <MapStateProvider
      id={id}
      center={center}
      extent={extent}
      zoom={zoom}
      maxZoom={maxZoom}
    >
      <MapContainer id={id} width={width} height={height} position="relative">
        {children}
      </MapContainer>
    </MapStateProvider>
  );
};

/**
 * TODO Document me
 */
Map.propTypes = {
  id: PropTypes.string.isRequired,
  center: PropTypes.array,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

Map.defaultProps = {
  center: undefined,
  width: '100%',
  height: '100%',
};

export default Map;
