import React, {
  useEffect,
  useRef
} from 'react';
import useMapViewer from '../../context/useMapViewer';
import SingleClickSelectInteraction from './SingleClickSelectInteraction';

const SelectReferenceLayerFeatureInteraction = () => {
  const { referenceLayerFeature: { feature }, selectReferenceLayerFeature } = useMapViewer();
  const selectRef = useRef(null);

  const handleOnSelect = event => {
    if (event.selected[0] && !event.selected[0].get('entity')) {
      const feature = event.selected[0];
      selectReferenceLayerFeature(feature);
    }
  };

  /**
   * Clear selections when entity is falsy.
   */
  useEffect(() => {
    if (!feature && selectRef.current) {
      selectRef.current.clear();
    }
  }, [feature]);

  return (
    <SingleClickSelectInteraction ref={selectRef} onSelect={handleOnSelect} />
  );
};

export default SelectReferenceLayerFeatureInteraction;
