import React from 'react';
import DetailMapInsert from './components/DetailMapInsert';
import DetailRow from './components/DetailRow';
import DetailSourceSystemLink from './components/DetailSourceSystemLink';

const AirPollutionSourceDetail = ({ entity }) => {
  return (
    <>
      <DetailMapInsert entity={entity} />
      <DetailRow label="Source Category" value={entity.sourceCategory} />
      <DetailRow label="Ownership Type" value={entity.ownershipType} />
      <DetailRow label="Operating Status" value={entity.operatingStatusType} />
      <DetailSourceSystemLink entity={entity} />
    </>
  );
};

export default AirPollutionSourceDetail;
