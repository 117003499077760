import { css } from "@emotion/core";
import styled from "@emotion/styled/macro";
import axios from "axios";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { Columns, NoWrapColumns } from "./components/CommonLayout";
import { SearchListItems } from "./components/SearchListItems";
import SearchBar from "./components/SearchBar";
import SmallerHeader from "./components/SmallerHeader";
import { SplitSelectIndex } from "./components/SplitSelect";
import EntityTypes from "./constants/EntityTypes";
import { SearchConsumer } from "./context/SearchContext";
import { Heading4 } from "./theme/commonType";
import { colors, spacing } from "./theme/theme";
import {
  ListColumn,
  HeaderContainer,
  SelectACategoryPrompt,
  ResultsColumn,
  ResultsCountBox,
  ListContainer,
  MatchedQuery,
} from "./SearchViewUI";
import { hwCounties, hwGenerators } from "./constants/HwEnums";

const SearchCategories = styled.ul`
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
`;
const titleFieldKey = "searchTitleName";
const descriptionFieldKey = "searchDescriptionText";
const statusFieldKey = "searchStatusText";
const entityUrlFieldKey = "entityUrl";
// TODO: Abstract out and improve our handling our dates/conversion to/from UTC.
export const getLocalMoment = (date) => {
  if (date && typeof date === "string" && date.length > 10 && !date.toString().endsWith("Z")) {
    date += "Z";
  }
  return moment(date);
};
const setIslandName = (entity) => {
  if (!entity._islandName) {
    if (entity.island) {
      entity._islandName = entity.island.name;
    } else {
      entity._islandName = "";
    }
  }
};

const categories = [
  {
    id: "emergencyReleases",
    defaultParams: {},
    addListViewFields: (item) => {
      Object.defineProperties(item, {
        [entityUrlFieldKey]: {
          get: function () {
            return `https://eha-cloud.doh.hawaii.gov/iheer/#!/incident/${this.id}/details/view`;
          },
        },
        [titleFieldKey]: {
          get: function () {
            return `${this.releaseName} (${this.caseNumber})`;
          },
        },
        [descriptionFieldKey]: {
          get: function () {
            setIslandName(this);
            return `${this._islandName} - ${this.summary}`;
          },
        },
        [statusFieldKey]: {
          get: function () {
            if (!this._statusField) {
              this._statusField = `Reported ${getLocalMoment(this.reportedOn).format(
                "MM/D/YY h:mm A zz"
              )}`;
            }
            return this._statusField;
          },
        },
      });
    },
  },
  {
    id: "waterQualityAdvisories",
    defaultParams: {},
    addListViewFields: (item) => {
      Object.defineProperties(item, {
        [entityUrlFieldKey]: {
          get: function () {
            return `https://eha-cloud.doh.hawaii.gov/cwb/#!/event/${this.id}/details/view`;
          },
        },
        [titleFieldKey]: {
          get: function () {
            return this.title;
          },
        },
        [descriptionFieldKey]: {
          get: function () {
            setIslandName(this);
            return `${this._islandName} - ${this.description}`;
          },
        },
        [statusFieldKey]: {
          get: function () {
            if (!this._statusField) {
              this._statusField = `Posted ${getLocalMoment(this.postedOn).format("ll")}`;
            }
            return this._statusField;
          },
        },
      });
    },
  },
  {
    id: "uicFacilities",
    defaultParams: {},
    addListViewFields: (item) => {
      Object.defineProperties(item, {
        [entityUrlFieldKey]: {
          get: function () {
            return `https://eha-cloud.doh.hawaii.gov/sdwb/#!/facility/${this.id}/details/view`;
          },
        },
        [titleFieldKey]: {
          get: function () {
            if (
              this.affiliatedOrganizations &&
              this.affiliatedOrganizations.length > 0 &&
              this.affiliatedOrganizations[0].facility
            ) {
              return this.affiliatedOrganizations[0].facility.name;
            }
            return this.name;
          },
        },
        [descriptionFieldKey]: {
          get: function () {
            if (!this._operatedBy) {
              if (
                this.affiliatedOrganizations &&
                this.affiliatedOrganizations.length > 0 &&
                this.affiliatedOrganizations[0].organization
              ) {
                this._operatedBy = ` - ${this.affiliatedOrganizations[0].organization.name}`;
              } else {
                this._operatedBy = "";
              }
            }
            if (!this._locatedAt) {
              if (this.street && this.city && this.zipCode) {
                this._locatedAt = ` - located at ${this.street} ${this.city} ${this.zipCode}`;
              } else {
                this._locatedAt = "";
              }
            }

            setIslandName(this);
            return this._islandName + this._operatedBy + this._locatedAt;
          },
        },
        [statusFieldKey]: {
          get: function () {
            if (!this._statusField) {
              this._statusField = `Last Updated ${getLocalMoment(this.updatedOn).format("ll")}`;
            }
            return this._statusField;
          },
        },
      });
    },
  },
  {
    id: "remediationSites",
    defaultParams: {},
    addListViewFields: (item) => {
      Object.defineProperties(item, {
        [entityUrlFieldKey]: {
          get: function () {
            return `https://eha-cloud.doh.hawaii.gov/iheer/#!/site/${this.id}/details/view`;
          },
        },
        [titleFieldKey]: {
          get: function () {
            return this.name;
          },
        },
        [descriptionFieldKey]: {
          get: function () {
            if (!this._locatedAt) {
              if (this.street && this.city && this.zipCode) {
                this._locatedAt = ` - located at ${this.street} ${this.city} ${this.zipCode}`;
              } else if (this.locationDescription) {
                this._locatedAt = ` - located at ${this.locationDescription}`;
              } else {
                this._locatedAt = "";
              }
            }

            setIslandName(this);
            return this._islandName + this._locatedAt;
          },
        },
        [statusFieldKey]: {
          get: function () {
            if (!this._statusField) {
              this._statusField = `Last Updated ${getLocalMoment(this.updatedOn).format("ll")}`;
            }
            return this._statusField;
          },
        },
      });
    },
  },
  {
    id: "airPollutionSources",
    defaultParams: { type: "Facility" },
    addListViewFields: (item) => {
      Object.defineProperties(item, {
        [entityUrlFieldKey]: {
          get: function () {
            return `https://eha-cloud.doh.hawaii.gov/cab/#!/facilitySite/${this.id}/details/view`;
          },
        },
        [titleFieldKey]: {
          get: function () {
            return `${this.name} (${this.number})`;
          },
        },
        [descriptionFieldKey]: {
          get: function () {
            if (!this._permittee) {
              if (
                this.affiliatedOrganizations &&
                this.affiliatedOrganizations.length > 0 &&
                this.affiliatedOrganizations[0].organization
              ) {
                this._permittee = ` - ${this.affiliatedOrganizations[0].organization.name}`;
              } else {
                this._permittee = "";
              }
            }
            if (!this._locatedAt) {
              if (this.street && this.city && this.zipCode) {
                this._locatedAt = ` - located at ${this.street} ${this.city} ${this.zipCode}`;
              } else {
                this._locatedAt = "";
              }
            }
            setIslandName(this);
            return this._islandName + this._permittee + this._locatedAt;
          },
        },
        [statusFieldKey]: {
          get: function () {
            if (!this._statusField) {
              this._statusField = `Last Updated ${getLocalMoment(this.updatedOn).format("ll")}`;
            }
            return this._statusField;
          },
        },
      });
    },
  },
  {
    id: "hwHandlers",
    defaultParams: {
      orderBy: [{ propertyName: "number", isDescending: false }],
      take: 500,
      skip: 0,
    },
    addListViewFields: (item) => {
      Object.defineProperties(item, {
        [entityUrlFieldKey]: {
          get: function () {
            return `https://eha-cloud.doh.hawaii.gov/hw/handler/${this.id}/view`;
          },
        },
        [titleFieldKey]: {
          get: function () {
            return `${this.number} - ${this.name}`;
          },
        },
        [descriptionFieldKey]: {
          get: function () {
            if (!this._generator) {
              this._generator = hwGenerators.get(this.federalWasteGenerator)?.longDescription;
              this._generator = this._generator ? ` - ${this._generator}` : "";
            }
            if (!this._locatedAt) {
              if (this.locationStreet1 && this.locationCity && this.locationZip) {
                this._locatedAt = ` - located at ${this.locationStreet1} ${this.locationCity} ${this.locationZip}`;
              } else {
                this._locatedAt = "";
              }
            }
            if (!this._islandName) this._islandName = hwCounties.get(this.countyCode)?.description;

            return this._islandName + this._generator + this._locatedAt;
          },
        },
        [statusFieldKey]: {
          get: function () {
            if (!this._statusField) {
              this._statusField = `Received ${getLocalMoment(this.receiveDate).format("ll")}`;
            }
            return this._statusField;
          },
        },
      });
    },
  },
  {
    id: "npdesFacilities",
    defaultParams: {
      hasLocations: true,
      hasPermits: true,
    },
    addListViewFields: (item) => {
      Object.defineProperties(item, {
        [entityUrlFieldKey]: {
          get: function () {
            return `https://eha-cloud.doh.hawaii.gov/cwb/#!/facilitySite/${this.id}/details/view`;
          },
        },
        [titleFieldKey]: {
          get: function () {
            return this.name;
          },
        },
        [descriptionFieldKey]: {
          get: function () {
            if (!this._locatedAt) {
              if (this.street && this.city && this.state && this.zipCode) {
                this._locatedAt = ` - located at ${this.street} ${this.city} ${this.state} ${this.zipCode}`;
              } else {
                this._locatedAt = ` - located at ${this.locationDescription}`;
              }
            }
            setIslandName(this);
            return `${this._islandName} ${this._locatedAt}`;
          },
        },
        [statusFieldKey]: {
          get: function () {
            if (!this._statusField) {
              this._statusField = `Last Updated ${getLocalMoment(this.updatedOn).format("ll")}`;
            }
            return this._statusField;
          },
        },
      });
    },
  },
].map((category) => ({
  ...category,
  name: EntityTypes[category.id].label,
  endpoint: EntityTypes[category.id].endpoint,
  description: EntityTypes[category.id].description,
}));

const getCategory = (id) => categories.find((x) => x.id === id);

const SearchView = (props) => {
  // const [selectedCategory, selectCategory] = useState();
  const [expandedItemId, setExpandedItemId] = useState(null);
  const [searchStatus, setSearchStatus] = useState();
  const [searchResults, setSearchResults] = useState("");
  const [searchResultsCategory, setResultsCategory] = useState("");
  let [searchQuery, setSearchQuery] = useState("");
  if (props.updateQuery) {
    searchQuery = props.query;
    setSearchQuery = props.updateQuery;
  }

  const selectedCategory = props.match.params.category;

  function handleItemClick(id) {
    setExpandedItemId(id !== expandedItemId ? id : null);
  }

  const doQuery = useCallback(() => {
    if (selectedCategory) {
      const category = getCategory(selectedCategory);
      const endpoint = category.endpoint;
      const params = { params: { ...category.defaultParams, keywords: searchQuery } };
      setSearchStatus("searching");
      return setTimeout(() => {
        axios.get(endpoint, params).then((results) => {
          setSearchStatus("complete");
          setResultsCategory(selectedCategory);
          // const results = advisories.filter(x => matchesQuery(x, searchQuery));
          // setSearchResults(advisories.slice(floor));
          // it'd be nice if they were all paged and we could rely on list at some point.
          let list = [];
          if (results.data) {
            if (results.data.list) list = results.data.list;
            else if (results.data.value) list = results.data.value;
            else list = results.data;
          }
          list.forEach((item) => {
            category.addListViewFields(item);
          });
          setSearchResults(list);
          // props.history.push({ ...props.location, search: '?q=' + searchQuery});
        });
      }, 300);
    } else setSearchStatus(null);
  }, [searchQuery, selectedCategory]);

  useEffect(() => {
    const id = doQuery(searchQuery);
    return () => {
      clearTimeout(id);
    };
  }, [selectedCategory, searchQuery, doQuery]);

  // wipe expanded-item state when screen changes significantly
  useEffect(() => {
    setExpandedItemId(null);
  }, [selectedCategory, searchResults]);

  const placeholder = selectedCategory
    ? `Search ${getCategory(selectedCategory).name}`
    : "Keyword Search";

  return (
    <>
      <HeaderContainer style={{ zIndex: 2 }}>
        <SmallerHeader />
      </HeaderContainer>
      {!selectedCategory && <SelectACategoryPrompt as="h2">Select:</SelectACategoryPrompt>}
      <NoWrapColumns>
        <ListColumn fillWidth={!searchStatus} usePadding={true} gutter={0}>
          <SearchCategories>
            {categories.map((cat, index) => (
              <SplitSelectIndex
                heading={cat.name}
                key={cat.id}
                isActive={cat.id === selectedCategory}
                borderBottom={index !== categories.length - 1}
                // onClick={() => navigate(cat.id)}>
                onClick={() => props.history.push(`/search/${cat.id}`)}
              >
                {cat.description}
              </SplitSelectIndex>
            ))}
          </SearchCategories>
        </ListColumn>
        {searchStatus && (
          <ResultsColumn usePadding={true}>
            <SearchBar
              variant="block"
              paddingH={spacing.screenEdgeMarginH}
              placeholder={placeholder}
              onChange={setSearchQuery}
              value={searchQuery}
            />
            <ResultsCountBox>
              {searchStatus === "searching" ? (
                <>Searching...</>
              ) : (
                <>
                  <span>
                    Showing {searchResults && <MatchedQuery>{searchResults.length}</MatchedQuery>}{" "}
                    {getCategory(selectedCategory).name}
                  </span>
                  {searchQuery && (
                    <span>
                      {" "}
                      matching <MatchedQuery>{searchQuery}</MatchedQuery>
                    </span>
                  )}
                </>
              )}
            </ResultsCountBox>
            <ListContainer>
              {searchResultsCategory === selectedCategory && (
                <SearchListItems
                  results={searchResults}
                  handleItemClick={handleItemClick}
                  expandedItemId={expandedItemId}
                  selectedCategory={selectedCategory}
                ></SearchListItems>
              )}
            </ListContainer>
          </ResultsColumn>
        )}
      </NoWrapColumns>
    </>
  );
};

export const ContextualSearchView = (props) => (
  <SearchConsumer>
    {({ query, updateQuery }) => <SearchView {...props} query={query} updateQuery={updateQuery} />}
  </SearchConsumer>
);

export default SearchView;
