import React from 'react';
import { css } from '@emotion/core';
import styled from '@emotion/styled/macro';
import Color from 'color';
import theme, { spacing } from '../theme/theme';
import {
  colorMap,
  typeScaleMap
} from '../theme/themeMapping';

const paddingHStandard = spacing.screenEdgeMarginH;
const paddingVStandard = 22;
const bgColor = colorMap.cardBgColor;
// const bgColorActive = colorMap.transparentHighlight;
const hoverColor = colorMap.cardHover;
const activeColor = colorMap.cardActive;
const bodyFontSize = typeScaleMap.cardBody;

// padding-top: ${(inputHeight - inputFontSize - inputBorderWidth) / 2}px;
// padding-bottom: ${(inputHeight - inputFontSize - inputBorderWidth) / 2}px;

export const ItemPanelBase = props => css`
  padding-top: ${props.paddingV != null ? props.paddingV : paddingVStandard}px;
  padding-bottom: ${props.paddingV != null ? props.paddingV : paddingVStandard}px;
  padding-left: ${props.paddingH != null ? props.paddingH : paddingHStandard}px;
  padding-right: ${props.paddingH != null ? props.paddingH : paddingHStandard}px;
  font-size: ${bodyFontSize}px;
  background: ${bgColor};
  color: ${colorMap.normalText};
`;

export const ItemPanelClickable = props => css`
  cursor: pointer;
  transition: ${theme.transitions.listItemBg};
`;

export const ItemPanelHover = props => css`
  background-color: ${hoverColor};
`;

export const ItemPanelClick = props => css`
  background-color: ${Color(hoverColor).darken(.2).toString()};
`;

const ItemPanel = styled.div`
  ${ItemPanelBase}
  ${props => props.clickable && !props.isActive && ItemPanelClickable}
  &:hover {
    ${props => props.clickable && !props.isActive && ItemPanelHover}
  }
  &:active {
    ${props => props.clickable && !props.isActive && ItemPanelClick}
  }
`;

export default ItemPanel;
