import styled from '@emotion/styled/macro';
import { Heading1 } from '../theme/commonType';
import { colorMap } from '../theme/themeMapping';

const AppBrand = styled(Heading1)`
  font-size: ${props => props.large ? 30 : 18}px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: .018em;
  color: ${colorMap.highlightText};
  strong {
    color: ${colorMap.boldText};
    font-weight: 700;
  }
`;

export default AppBrand;
