import styled from "@emotion/styled";
import { Box, Icon, Icons, Text, TextInput } from "@modernary/ui-kit-core";
import React, { useEffect, useRef, useState } from "react";
import { fromEvent } from "rxjs";

import { debounceTime, map } from "rxjs/operators";
import ToolPanels from "../../constants/ToolPanels";
import { colors } from "../../theme/theme";
import useMapViewer from "../context/useMapViewer";
import formatNumber from "../util/formatNumber";

const SearchOptionsIcon = styled(Icon)`
  &:hover {
    cursor: pointer;
  }
`;

const SearchInput = () => {
  const {
    executeSearch,
    toggleSearchOptions,
    search: { isSearching, results, term },
    omniBox: { panel },
    searchOptions,
  } = useMapViewer();
  const [value, setValue] = useState(term);
  const inputRef = useRef(null);

  /**
   * Bind keyup and debounce calls to the search api.
   */
  useEffect(() => {
    const subscription = fromEvent(inputRef.current, "keyup")
      .pipe(
        map((e) => e.currentTarget.value),
        debounceTime(350)
      )
      .subscribe((term) => executeSearch(term, searchOptions));

    return () => subscription.unsubscribe();
  }, [inputRef, executeSearch]);

  /**
   * Save input value to local state.
   */
  const handleOnChange = async (text) => {
    if (!text) {
      await executeSearch("", searchOptions);
    }
    setValue(text);
  };

  /**
   * Updates the input value from context state.
   */
  useEffect(() => {
    setValue(term);
  }, [term]);

  /**
   * Focus input on mount.
   */
  useEffect(() => {
    inputRef.current.focus();
  }, []);

  const addonEnd = (
    <>
      <span title="Change the data sets that are included">
        <SearchOptionsIcon
          name={Icons.Settings}
          color="#0B76CC"
          size={18}
          mr={12}
          ml={6}
          onClick={toggleSearchOptions}
        />
      </span>
      {isSearching && <Icon name={Icons.LoadingBubbles} color="#0B76CC" size={18} mr={12} />}
    </>
  );

  return (
    <>
      <TextInput
        addonStart={<Icon name={Icons.Search} color={colors.mediumGray} size={12} ml={12} />}
        addonEnd={addonEnd}
        ref={inputRef}
        placeholder="Search"
        variant={["default", "search"]}
        value={value}
        onChange={handleOnChange}
      />
      {panel === ToolPanels.SearchResults && (
        <Box textAlign="center" py="xsmall">
          <Text fontSize={13} color="grey.500">
            {`Search Results (${formatNumber(results.length)})`}
          </Text>
        </Box>
      )}
    </>
  );
};

export default SearchInput;
