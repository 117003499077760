import { Icons } from "@modernary/ui-kit-core";
import Point from "ol/geom/Point";
import React from "react";
import MapConstants from "../../../constants/MapConstants";
import useMap from "../../../map/context/useMap";
import { toWebMercatorCenter } from "../../../map/util/transform";
import ToolBarItem from "./ToolBarItem";

const MyLocationToolBarItem = () => {
  const { map } = useMap();

  const success = (position) => {
    if (position.coords) {
      const { longitude, latitude } = position.coords;
      const resolution = map.getView().getResolutionForZoom(14);
      map.getView().fit(new Point(toWebMercatorCenter([longitude, latitude])), {
        padding: [0, 0, 0, MapConstants.omniBox.width * 2],
        minResolution: resolution,
        duration: MapConstants.duration,
      });
    }
  };

  const error = () => {
    window.alert("Unable to retrieve your location");
  };

  const handleOnClick = () => {
    if (!map) {
      return;
    }

    if (!navigator.geolocation) {
      window.alert("Geolocation is not supported by your browser");
      return;
    }

    navigator.geolocation.getCurrentPosition(success, error);
  };

  return (
    <ToolBarItem
      iconName={Icons.Location}
      onClick={handleOnClick}
      title="Zoom to your location on the map"
    />
  );
};

export default MyLocationToolBarItem;
