import {
  useCallback,
  useReducer
} from 'react';
import useIsMounted from './useIsMounted';

export default (reducer, initialState) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const isMounted = useIsMounted();

  const guardedDispatch = useCallback((...args) => {
    if (isMounted) {
      dispatch(...args);
    }
  }, [dispatch, isMounted]);

  return [state, guardedDispatch];
};
