import {
  ACTION_CLOSE_FLYOUT_PANEL,
  ACTION_DESELECT_REFERENCE_LAYER_FEATURE,
  ACTION_DESELECT_RESULT_ITEM,
  ACTION_OPEN_FLYOUT_PANEL,
  ACTION_SEARCH_BEGIN,
  ACTION_SEARCH_ERROR,
  ACTION_SEARCH_SUCCESS,
  ACTION_SELECT_REFERENCE_LAYER_FEATURE,
  ACTION_SELECT_RESULT_ITEM,
  ACTION_SET_ACTIVE_TOOL_PANEL,
  ACTION_SET_BASE_LAYER,
  ACTION_SET_REFERENCE_LAYERS
} from './actions-types';

/**
 *  Private helper to create actions with a consistent shape. The shape is
 *  inspired by the redux community.
 *
 *  Ref: https://shorturl.at/cdgNW
 *
 */
const action = (type, payload) => {
  return {
    type: type,
    payload: {
      ...payload,
    },
  };
};

export const actionSearchBegin = (term, searchOptions) => action(ACTION_SEARCH_BEGIN, {
  term,
  searchOptions
});
export const actionSearchSuccess = results => action(ACTION_SEARCH_SUCCESS, { results });
export const actionSearchError = error => action(ACTION_SEARCH_ERROR, { error });
export const actionSelectResultItem = ({  index, item, entityLocation, clickCoordinates }) => action(ACTION_SELECT_RESULT_ITEM, {
  item,
  index,
  entityLocation,
  clickCoordinates
});
export const actionDeselectResultItem = () => action(ACTION_DESELECT_RESULT_ITEM);
export const actionSetActivePanel = panel => action(ACTION_SET_ACTIVE_TOOL_PANEL, { panel });
export const actionSetBaseLayer = layer => action(ACTION_SET_BASE_LAYER, { layer });
export const actionSetReferenceLayers = layers => action(ACTION_SET_REFERENCE_LAYERS, { layers });
export const actionSelectReferenceLayerFeature = feature => action(ACTION_SELECT_REFERENCE_LAYER_FEATURE, { feature });
export const actionDeselectReferenceLayerFeature = () => action(ACTION_DESELECT_REFERENCE_LAYER_FEATURE);
export const actionOpenFlyoutPanel = panel => action(ACTION_OPEN_FLYOUT_PANEL, { panel });
export const actionCloseFlyoutPanel = () => action(ACTION_CLOSE_FLYOUT_PANEL);
