import React from 'react';
import DetailMapInsert from './components/DetailMapInsert';
import DetailRow from './components/DetailRow';
import DetailSourceSystemLink from './components/DetailSourceSystemLink';

const RemediationSiteDetail = ({ entity }) => {
  return (
    <>
      <DetailMapInsert entity={entity} />
      <DetailRow label="Lead Agency" value={entity.leadAgency} />
      <DetailRow label="Program" value={entity.program} />
      <DetailRow label="Status" value={entity.status} />
      <DetailSourceSystemLink entity={entity} />
    </>
  );
};

export default RemediationSiteDetail;
