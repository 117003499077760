import styled from "@emotion/styled";
import { Absolute, Box, Button, Flex, Icons, Text } from "@modernary/ui-kit-core";
import isString from "lodash/isString";
import OLOverlay from "ol/Overlay";
import React, { useEffect, useRef } from "react";
import { ListItemTitle } from "../../../components/SearchListItems";
import useMap from "../../../map/context/useMap";

const OverlayRoot = styled(Absolute)`
  position: absolute;
  background-color: white;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
  border-radius: 2px;
  border: 1px solid #cccccc;
  bottom: 12px;
  left: -50px;

  &:after,
  &:before {
    top: 100%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }

  &:after {
    border-top-color: white;
    border-width: 10px;
    left: 48px;
    margin-left: -10px;
  }

  &:before {
    border-top-color: #cccccc;
    border-width: 11px;
    left: 48px;
    margin-left: -11px;
  }
`;

const Title = ({ children }) => {
  return (
    <Text fontSize={14} color="primary.600" fontWeight={600}>
      {children}
    </Text>
  );
};

const Overlay = ({ title, position, style, children, onAfterClose }) => {
  const { map } = useMap();
  const overlayRef = useRef(null);
  const elementRef = useRef(null);

  /**
   * Setup ol/Overlay
   */
  useEffect(() => {
    if (elementRef.current) {
      overlayRef.current = new OLOverlay({
        element: elementRef.current,
        autoPan: true,
        autoPanAnimation: {
          duration: 250,
        },
      });
    }
  }, [elementRef]);

  /**
   * Push overlay onto map.
   */
  useEffect(() => {
    if (!map) {
      return;
    }

    if (!overlayRef.current) {
      return;
    }

    map.getOverlays().push(overlayRef.current);
  }, [map, overlayRef]);

  /**
   * Update position.
   */
  useEffect(() => {
    if (!overlayRef.current) {
      return;
    }
    overlayRef.current.setPosition(position);
  }, [position]);

  /**
   * Handle closing overlay.
   */
  const handleOnClickClose = () => {
    overlayRef.current.setPosition(undefined);
    onAfterClose && onAfterClose();
  };

  const componentTitle = isString(title) ? (
    <ListItemTitle>{title}</ListItemTitle>
  ) : (
    <Title>{title}</Title>
  );

  return (
    <OverlayRoot ref={elementRef} style={style}>
      <Flex column>
        <Flex.Item flex={1} p="small">
          {componentTitle}
        </Flex.Item>
        <Flex.Item flex={0} p="xxsmall">
          <Button
            baseColor="grey"
            icon={Icons.X}
            fill="ghost"
            shape="round"
            onClick={handleOnClickClose}
          />
        </Flex.Item>
      </Flex>
      <Box maxHeight={200} style={{ overflow: "hidden", overflowY: "auto" }}>
        {children}
      </Box>
    </OverlayRoot>
  );
};

export default Overlay;
