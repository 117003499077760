import { useEffect } from 'react';
import useMap from '../../../map/context/useMap';
import useMapViewer from '../../context/useMapViewer';

const MapFeatureClickListener = ({}) => {
  const { map } = useMap();
  const { selection: { item: entity }, selectResultItem } = useMapViewer();

  useEffect(() => {
    if (!map) {
      return;
    }

    const clickListener = event => {
      const features = [];

      map.forEachFeatureAtPixel(event.pixel, feature => {
        features.push(feature);
      });

      if (features[0]) {
        const props = features[0].getProperties();
        selectResultItem({
          index: props['index'],
          item: props['entity'],
          entityLocation: props['entityLocation'],
          clickCoordinates: event.coordinate
        });
      }
    };

    map.on('singleclick', clickListener);

    return () => {
      map.un('singleclick', clickListener);
    };
  }, [map]);

  return null;
};

export default MapFeatureClickListener;
