import Feature from 'ol/Feature';
import Point from 'ol/geom/Point';
import Circle from 'ol/style/Circle';
import Fill from 'ol/style/Fill';
import Stroke from 'ol/style/Stroke';
import Style from 'ol/style/Style';
import React, {
  useEffect,
  useState
} from 'react';
import useMap from '../../../map/context/useMap';
import FeaturesLayer from '../../../map/layer/FeaturesLayer';
import { toWebMercatorCenter } from '../../../map/util/transform';

const MyLocationLayer = () => {
  const { map } = useMap();
  const [myLocation, setMyLocation] = useState(null); // array

  useEffect(() => {
    if (!map) {
      return;
    }

    const success = position => {
      if (position.coords) {
        const { longitude, latitude } = position.coords;

        const feature = new Feature({
          type: 'icon',
          geometry: new Point(toWebMercatorCenter([longitude, latitude]))
        });

        const styles = [
          new Style({
            image: new Circle({
              radius: 14,
              fill: new Fill({
                color: 'rgba(51, 153, 204, 0.3)',
              }),
            }),
          }),
          new Style({
            image: new Circle({
              radius: 6,
              fill: new Fill({
                color: 'rgba(51, 153, 204, 0.9)',
              }),
              stroke: new Stroke({
                color: '#fff',
                width: 2,
              }),
            }),
          }),
        ];

        feature.setStyle(styles);

        setMyLocation([feature]);
      }
    };

    const error = () => {}; // swallow it.

    if (!navigator.geolocation) {
      window.alert('Geolocation is not supported by your browser');
      return;
    }

    navigator.geolocation.getCurrentPosition(success, error);

  }, [map]);

  return (
    <FeaturesLayer id="MyLocation" features={myLocation} />
  );
};

export default MyLocationLayer;
