import React, { Component } from 'react';

const SearchContext = React.createContext();

export class SearchProvider extends Component {
    constructor(props) {
        super(props);

        //TODO: Could eventually expand to include info about either search in progress, etc.
        this.state = {
            query: "",
            updateQuery: this.updateQuery,
        };
    }

    updateQuery = query => {
        this.setState({...this.state, query});
    };

    render() {
        const { children } = this.props;

        return (
            // Passing state prevents unnecessary child re-renders
            // https://reactjs.org/docs/context.html#caveats
            <SearchContext.Provider value={this.state}>
                {children}
            </SearchContext.Provider>
        );
    }
}


export const SearchConsumer = SearchContext.Consumer;