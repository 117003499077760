export const ACTION_SEARCH_BEGIN = 'ACTION_SEARCH_BEGIN';
export const ACTION_SEARCH_SUCCESS = 'ACTION_SEARCH_SUCCESS';
export const ACTION_SEARCH_ERROR = 'ACTION_SEARCH_ERROR';
export const ACTION_SELECT_RESULT_ITEM = 'SELECT_RESULT_ITEM';
export const ACTION_DESELECT_RESULT_ITEM = 'DESELECT_RESULT_ITEM';
export const ACTION_SELECT_REFERENCE_LAYER_FEATURE = 'ACTION_SELECT_REFERENCE_LAYER_FEATURE';
export const ACTION_DESELECT_REFERENCE_LAYER_FEATURE = 'ACTION_DESELECT_REFERENCE_LAYER_FEATURE';
export const ACTION_SET_ACTIVE_TOOL_PANEL = 'ACTION_SET_ACTIVE_TOOL_PANEL';
export const ACTION_SET_BASE_LAYER = 'ACTION_SET_BASE_LAYER';
export const ACTION_SET_REFERENCE_LAYERS = 'ACTION_SET_REFERENCE_LAYERS';
export const ACTION_OPEN_FLYOUT_PANEL = 'ACTION_OPEN_FLYOUT_PANEL';
export const ACTION_CLOSE_FLYOUT_PANEL = 'ACTION_CLOSE_FLYOUT_PANEL';
