import styled from "@emotion/styled";
import { Absolute } from "@modernary/ui-kit-core";
import React from "react";
import { Flipped, Flipper } from "react-flip-toolkit";
import FlyoutPanels from "../../constants/FlyoutPanels";
import useMapViewer from "../context/useMapViewer";
import EntityDetail from "./detail/EntityDetail";
import SearchOptions from "./search/SearchOptions";

const FLIP_ID = "fullscreen";

const Root = styled(Absolute)`
  background-color: white;
  width: 100%;
  height: 100%;
  overflow: hidden;
  overflow-y: auto;
`;

const HiddenRoot = styled(Root)`
  transform: translateX(0%);
`;

const ShowingRoot = styled(Root)`
  transform: translateX(100%);
`;

const HiddenRootFlipped = ({ item, flyOutPanel }) => (
  <Flipped flipId={FLIP_ID}>
    <HiddenRoot shadow="xxsmall">
      {flyOutPanel === FlyoutPanels.EntityDetails && <EntityDetail entity={item} />}
      {flyOutPanel === FlyoutPanels.SearchOptions && <SearchOptions />}
    </HiddenRoot>
  </Flipped>
);

const ShowingRootFlipped = ({ item, flyOutPanel }) => (
  <Flipped flipId={FLIP_ID}>
    <ShowingRoot shadow="xxsmall">
      {flyOutPanel === FlyoutPanels.EntityDetails && <EntityDetail entity={item} />}
      {flyOutPanel === FlyoutPanels.SearchOptions && <SearchOptions />}
    </ShowingRoot>
  </Flipped>
);

const FlyoutRoot = () => {
  const {
    selection: { hasSelection, item },
    omniBox: { isFlyoutOpen, flyOutPanel },
  } = useMapViewer();

  return (
    <Flipper flipKey={isFlyoutOpen}>
      {isFlyoutOpen ? (
        <ShowingRootFlipped item={item} flyOutPanel={flyOutPanel} />
      ) : (
        <HiddenRootFlipped item={item} flyOutPanel={flyOutPanel} />
      )}
    </Flipper>
  );
};

export default FlyoutRoot;
