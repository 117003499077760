import styled from "@emotion/styled";
import { Badge, Flex, Icon } from "@modernary/ui-kit-core";
import PropTypes from "prop-types";
import React from "react";

const Root = styled(Flex.Item)`
  &:hover {
    background-color: #0966af;
    cursor: pointer;
  }
`;

const ToolBarItem = ({ iconName, onClick, title, badgeLabel }) => {
  if (badgeLabel) {
    return (
      <Root p="xsmall" width={36} height={36} flex={0} onClick={onClick} title={title}>
        <Badge label={badgeLabel} bg="red.600" color="white">
          <Icon name={iconName} size={18} color="white" />
        </Badge>
      </Root>
    );
  }

  return (
    <Root p="xsmall" width={36} height={36} flex={0} onClick={onClick} title={title}>
      <Icon name={iconName} size={18} color="white" />
    </Root>
  );
};

ToolBarItem.propTypes = {
  iconName: PropTypes.string.isRequired,
};

export default ToolBarItem;
