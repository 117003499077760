import { Icon, Icons } from "@modernary/ui-kit-core";
import PropTypes from "prop-types";
import React from "react";
import EntityTypes from "../../constants/EntityTypes";

const EntityIndicator = ({ entity, size }) => {
  const type = entity.extra.type;
  return <Icon size={size} name={Icons.Marker} color={EntityTypes[type].colorMarker} />;
};

EntityIndicator.propTypes = {
  entity: PropTypes.object.isRequired,
  size: PropTypes.number,
};

EntityIndicator.defaultProps = {
  size: 16,
};

export default EntityIndicator;
