import React from "react";
import { Icon } from "@modernary/ui-kit-core";
import { getLineHeight } from "../theme/commonType";
import Chevron from "./Chevron";
import { typeScaleMap, colorMap } from "../theme/themeMapping";
import Card, { CardHeader, CardHeading, CardHeaderItem, CardBody } from "./Card";
import WrappedInlineSvg from "./WrappedInlineSvg";

const headingFontSize = typeScaleMap.cardHeading;

const CalloutCard = ({
  titleHeadingLevel,
  title,
  chevron,
  description,
  children,
  iconSrc,
  ...props
}) => {
  const headingLevel = props.titleHeadingLevel || "h3";
  // calculate chevron vertical offset
  const chevronPadding = (headingFontSize * getLineHeight(headingLevel) - headingFontSize) / 2;

  return (
    <Card {...props}>
      {title && (
        <CardHeader>
          {/* {props.icon &&
            (typeof props.icon === 'string')
              ? <Icon name={props.icon} size={headingFontSize}></Icon>
              : <WrappedInlineSvg size={headingFontSize + 'px'} src={props.icon}></WrappedInlineSvg>
          } */}
          {iconSrc && (
            <CardHeaderItem>
              <WrappedInlineSvg
                size={headingFontSize * 1.5 + "px"}
                src={iconSrc}
                color={colorMap.cardHighlight}
              ></WrappedInlineSvg>
            </CardHeaderItem>
          )}
          {/* <WrappedInlineSvg size={headingFontSize + 'px'} src={airPollutionSvg} /> */}
          <CardHeaderItem flex={1}>
            <CardHeading as={headingLevel}>{title}</CardHeading>
          </CardHeaderItem>
          {chevron && (
            <CardHeaderItem>
              <Chevron size={headingFontSize + "px"} color={colorMap.cardHighlight}></Chevron>
            </CardHeaderItem>
          )}
        </CardHeader>
      )}
      {description && <div>{description}</div>}
      {children && <CardBody>{children}</CardBody>}
    </Card>
  );
};

export default CalloutCard;
