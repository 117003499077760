import axios from 'axios';
import EsriJSON from 'ol/format/EsriJSON';
import { Vector as VectorLayer } from 'ol/layer';
import { tile as tileStrategy } from 'ol/loadingstrategy';
import VectorSource from 'ol/source/Vector';
import {
  Fill,
  Stroke,
  Style
} from 'ol/style';
import { createXYZ } from 'ol/tilegrid';
import React, { useEffect } from 'react';
import { isOk } from '../../map-viewer/context/api';
import useMap from '../context/useMap';

const esriJsonFormat = new EsriJSON();

const ArcGISFeatureService = ({ url: serviceUrl, layerId, color, minZoom, extraKey = 'extra', extraProperties }) => {
  const { map } = useMap();

  useEffect(() => {
    if (!map) {
      return;
    }

    const vectorSource = new VectorSource({
      loader: function (extent, resolution, projection) {
        const url = serviceUrl + '/' + layerId + '/query/?f=json&' +
          'returnGeometry=true&spatialRel=esriSpatialRelIntersects&geometry=' +
          encodeURIComponent('{"xmin":' + extent[0] + ',"ymin":' +
            extent[1] + ',"xmax":' + extent[2] + ',"ymax":' + extent[3] +
            ',"spatialReference":{"wkid":102100}}') +
          '&geometryType=esriGeometryEnvelope&inSR=102100&outFields=*' +
          '&outSR=102100';

        axios.get(url).then(response => {
          if (isOk(response)) {

            const features = esriJsonFormat.readFeatures(response.data, {
              featureProjection: projection
            });

            features.forEach(feature => {
              feature.set(extraKey, extraProperties);
            });

            if (features.length > 0) {
              vectorSource.addFeatures(features);
            }
          }
        });

      },
      strategy: tileStrategy(createXYZ({
        tileSize: 512
      }))
    });

    const vector = new VectorLayer({
      minZoom: minZoom,
      source: vectorSource,
      style: function (feature) {
        return new Style({
          fill: new Fill({
            color: color
          }),
          stroke: new Stroke({
            color: 'rgba(110, 110, 110, 255)',
            width: 0.4
          })
        });
      }
    });

    map.getLayers().push(vector);

    return () => {
      map.removeLayer(vector);
    };
  }, [map]);

  return null;
};

export default ArcGISFeatureService;
