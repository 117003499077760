import React from 'react';
import DetailMapInsert from './components/DetailMapInsert';
import DetailRow from './components/DetailRow';
import DetailSourceSystemLink from './components/DetailSourceSystemLink';

const EmergencyReleaseDetail = ({ entity }) => {

  return (
    <>
      <DetailRow label="Release Name" value={entity.releaseName} />
      <DetailRow label="Location" value={entity.location} />
      <DetailMapInsert entity={entity} />
      <DetailRow label="Reported On" value={entity.reportedOn} />
      <DetailRow label="Case Number" value={entity.caseNumber} />
      <DetailRow label="Summary" value={entity.summary} vertical />
      <DetailRow label="Status" value={entity.status} />
      <DetailSourceSystemLink entity={entity} />
    </>
  );
};

export default EmergencyReleaseDetail;
