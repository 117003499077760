import { Text } from "@modernary/ui-kit-core";
import React from "react";
import { colorMap } from "../../../../theme/themeMapping";

const Label = ({ children, ...rest }) => {
  return (
    <Text fontSize={13} fontWeight={600} color={colorMap.normalText} {...rest}>
      {children}
    </Text>
  );
};

export default Label;
