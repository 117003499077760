import { useContext } from 'react';
import FlyoutPanels from '../../constants/FlyoutPanels';
import MapConstants from '../../constants/MapConstants';
import ToolPanels from '../../constants/ToolPanels';
import api from './api';
import { MapViewerContext } from './MapViewerProvider';
import {
  actionCloseFlyoutPanel,
  actionDeselectReferenceLayerFeature,
  actionDeselectResultItem,
  actionOpenFlyoutPanel,
  actionSearchBegin,
  actionSearchError,
  actionSearchSuccess,
  actionSelectReferenceLayerFeature,
  actionSelectResultItem,
  actionSetActivePanel,
  actionSetBaseLayer,
  actionSetReferenceLayers
} from './state/actions';

const useMapViewer = () => {
  const [state, dispatch] = useContext(MapViewerContext);

  const executeSearch = async (term, searchOptions) => {

    if (state.omniBox.flyOutPanel === FlyoutPanels.EntityDetails) {
      dispatch(actionCloseFlyoutPanel());
      dispatch(deselectResultItem());
    }

    dispatch(actionSetActivePanel(ToolPanels.SearchResults));

    try {
      dispatch(actionSearchBegin(term, searchOptions));
      const results = await api.search({ term, searchOptions });
      dispatch(actionSearchSuccess(results));
    } catch (err) {
      dispatch(actionSearchError(err));
    }
  };

  const setActivePanel = panelId => {
    dispatch(actionSetActivePanel(panelId));
  };

  const setBaseLayer = layer => {
    dispatch(actionSetBaseLayer(layer));
  };

  const setReferenceLayers = layers => {
    dispatch(actionSetReferenceLayers(layers));
  };

  const selectResultItem = args => {
    dispatch(actionSelectResultItem(args));
    dispatch(actionDeselectReferenceLayerFeature());
    dispatch(actionOpenFlyoutPanel(FlyoutPanels.EntityDetails));
  };

  const deselectResultItem = () => {
    dispatch(actionDeselectResultItem());
    if (state.omniBox.flyOutPanel === FlyoutPanels.EntityDetails) {
      dispatch(actionCloseFlyoutPanel());
    }
  };

  const selectReferenceLayerFeature = feature => {
    dispatch(actionDeselectResultItem());
    dispatch(actionSelectReferenceLayerFeature(feature));
    dispatch(actionCloseFlyoutPanel());
  };

  const deselectReferenceLayerFeature = () => {
    dispatch(actionDeselectReferenceLayerFeature());
  };

  const toggleSearchOptions = () => {
    const { omniBox } = state;
    if (omniBox.isFlyoutOpen && omniBox.flyOutPanel === FlyoutPanels.SearchOptions) {
      dispatch(actionCloseFlyoutPanel());
    } else {
      dispatch(actionOpenFlyoutPanel(FlyoutPanels.SearchOptions));
    }
  };

  const reset = async () => {
    deselectResultItem();
    deselectReferenceLayerFeature();
    setActivePanel(MapConstants.defaultPanel);
    setBaseLayer(MapConstants.defaultBaseLayer);
    dispatch(actionCloseFlyoutPanel());
    await executeSearch(MapConstants.defaultSearchTerm, MapConstants.defaultSearchOptions);
  };

  return {
    executeSearch: executeSearch,
    selectResultItem: selectResultItem,
    deselectResultItem: deselectResultItem,
    selectReferenceLayerFeature: selectReferenceLayerFeature,
    deselectReferenceLayerFeature: deselectReferenceLayerFeature,
    setActivePanel: setActivePanel,
    setBaseLayer: setBaseLayer,
    setReferenceLayers: setReferenceLayers,
    toggleSearchOptions: toggleSearchOptions,
    reset: reset,
    search: state.search,
    searchOptions: state.searchOptions,
    selection: state.selection,
    omniBox: state.omniBox,
    map: state.map,
    referenceLayerFeature: state.referenceLayerFeature,
  };
};

export default useMapViewer;
